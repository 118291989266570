import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react/dist/iconify";
import { useTranslation } from "react-i18next";
import API from "../helpers/Axios";
import { store } from "../store/configureStore";
import { useNavigate } from "react-router-dom";

const SocialsConnector = ({ title }) => {
    const { t } = useTranslation();
    const linkTo = useNavigate();

    useEffect(() => {
        function handleMessage(e) {
            console.log(e.data);
            store.dispatch({ type: 'UPDATE_ACCESS_TOKEN', accessToken: e.data.token });
            setTimeout(() => {
                API.post('auth/user')
                    .then(res => {
                        store.dispatch({ type: 'TOGGLE_AUTH', user: res.data });
                        setTimeout(() => {
                            if (res.data.name) {
                                if (res.data.subscription) {
                                    linkTo('/dashboard');
                                    // } else if (res.data.age) {
                                    //     linkTo('/dashboard');

                                    // } else {
                                    //     linkTo('/dashboard/settings'); 
                                    // }

                                    // } else if (new Date(res.data.created_at) > new Date(Date.now() - 1 * 24 * 60 * 60 * 1000)) {
                                } else if (new Date(res.data.created_at) > new Date('2023-12-30')) {

                                    linkTo('/dashboard');

                                }
                                else {
                                    //linkTo('/subscribe-offers/10');
                                    linkTo('/dashboard');

                                }

                            } else {
                                linkTo('/dashboard/settings');
                            }
                        }, 100);
                    });
            }, 500);
        }
        window.addEventListener('message', handleMessage);
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    return (
        <>
            {/* {window.FACEBOOK_LOGIN && (
                <button
                    onClick={() => {
                        window.open('/connect/redirect/facebook', "_blank");
                    }}
                    className="btn btn-facebook btn-block d-flex align-items-center justify-content-between" type="button">
                    <Icon icon="ic:baseline-facebook" width="24" />
                    <div className="flex-grow-1 text-center">{title} Facebook</div>
                </button>
            )} */}
            {window.FACEBOOK_LOGIN && (
                <button
                    onClick={() => {
                        window.open('/connect/redirect/google', "_blank");
                    }}
                    className="btn btn-google btn-block d-flex align-items-center justify-content-between" type="button">
                    <Icon icon="ant-design:google-circle-filled" width="24" />
                    <div className="flex-grow-1 text-center">{title} Google</div>
                </button>
            )}
            {window.FACEBOOK_LOGIN && (
                <button
                    onClick={() => {
                        window.open('/connect/redirect/twitter', "_blank");
                    }}
                    className="btn btn-twitter btn-block d-flex align-items-center justify-content-between" type="button">
                    <Icon icon="ic:baseline-twitter" width="24" />
                    <div className="flex-grow-1 text-center">{title} Twitter</div>
                </button>
            )}
        </>
    )
}

export default SocialsConnector;
