import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ToolCard from "../../components/ToolCard";
import ToolCardCounter from "../../components/ToolCardCounter";
import ToolCardDisable from "../../components/ToolCardDisable";
import ToolCardNoSubscription from "../../components/ToolCardNoSubscription";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import tools from "../../helpers/tools.json";
import API from "../../helpers/Axios";


const DashboardPage = ({ auth }) => {

    const [settings, setSettings] = useState([]);
    const getSettings = () => {
        API.post('settings')
            .then(res => {
                setSettings(res.data);
            }).catch(error => {
                console.log(error);
            });
    }

    useEffect(() => {
        getSettings();
    }, []);

    // Parse the "free_templates" array from the settings
    const freeTemplatesArray = JSON.parse(settings.free_templates || '[]');

    // Use the parsed array to select elements from tools[0].tools
    const popularTools = freeTemplatesArray.map(index => tools[0].tools[index]);



    const linkTo = useNavigate();
    const { t, i18n } = useTranslation();

    const [tabIndex, setTabIndex] = useState(
        JSON.parse(localStorage.getItem("toolsTabIndex")) || 0
    );
    useEffect(() => {
        localStorage.setItem("toolsTabIndex", JSON.stringify(tabIndex));
    }, [tabIndex]);


    // Function to check if item.alt_name exists in auth.user.subscription.templates
    const isAltNameInTemplates = (altName) => {
        return auth?.user?.subscription?.templates?.includes(altName);
    };

    const isAltNameInAddonTemplates = (altName) => {
        const today = new Date();

        const addonTemplatesJSON = auth?.user?.addon_templates || '[]'; // Default to an empty array if it's not provided

        // Parse the JSON string into an array
        const addonTemplates = JSON.parse(addonTemplatesJSON);

        if (!Array.isArray(addonTemplates)) {
            // Handle the case where addon_templates is not an array
            return false; // or do something else
        }

        return addonTemplates.some((template) => {
            const templateDate = new Date(template.created_at);

            // Calculate the date one month from templateDate
            const oneMonthLater = new Date(templateDate);
            oneMonthLater.setMonth(oneMonthLater.getMonth() + 100);

            return (
                template.alt_name === altName &&
                templateDate < today && // Check if created_at is before today
                today <= oneMonthLater // Check if today is less than or equal to created_at + 1 month
            );
        });
    };

    return (
        <>
            <div className="">
                <h2><span className="fw-bolder">{auth.user.name}</span> <span className="text-secondary"> {t('— Lets make something remarkable today!')}</span></h2>
            </div>
            <div className="row mt-4 g-lg-4 g-2">
                {[
                    {
                        title: t('Words Generated'),
                        description: `${auth.user.words_generated}` + t('words')
                    },
                    {
                        title: t('Items Generated'),
                        description: `${auth.user.items_generated} ` + t('items')
                    },
                    {
                        title: t('Time Saved'),
                        description: `${(auth.user.words_generated / 500).toFixed(0)} ` + t('hours')
                    },
                    {
                        title: t('Tools Used'),
                        description: `${auth.user.tools_used}/54`
                    }
                ].map((item, index) => (
                    <div key={index} className="col-lg-3 col-6">
                        <div className="h-100 card p-3">
                            <p className="text-muted mb-1 fw-bold">{item.title}</p>
                            <p className="fw-bold mb-0">{item.description}</p>

                        </div>
                    </div>
                ))}
            </div>
            <div className="mt-4 d-flex justify-content-between align-items-center">
                <div>
                    <h2 className="fw-bolder">{t('Free Tools')}</h2>
                    <p className="mb-0">{t('Free trial tools')}</p>
                </div>

            </div>
            <div className="row mt-2 tools-list g-lg-4 g-3">
                {popularTools.map((item, index) => {
                    const freeTokens = JSON.parse(settings.user_free_tokens);
                    const totalFreeTokens = JSON.parse(settings.free_tokens);

                    const freeTokensValue = freeTokens[index];
                    const totalFreeValue = totalFreeTokens[index];

                    if (freeTokensValue > 0) {
                        return (
                            <>
                                <ToolCardCounter item={item} freeTokensValue={freeTokensValue} totalFreeValue={totalFreeValue} />
                            </>
                        );
                    } else if (auth.user.subscription) {
                        if (isAltNameInTemplates(item.alt_name) || isAltNameInAddonTemplates(item.alt_name)) {
                            return (
                                <>
                                    <ToolCard item={item} key={index} />
                                </>
                            );
                        } else {
                            return (
                                <>
                                    <ToolCardDisable item={item} key={index} />
                                </>
                            );
                        }
                    } else {
                        if (isAltNameInAddonTemplates(item.alt_name)) {
                            return (
                                <>
                                    <ToolCard item={item} key={index} />
                                </>
                            );
                        } else {
                            return (
                                <>
                                    <ToolCardNoSubscription item={item} key={index} />
                                </>
                            );
                        }
                    }
                })}
            </div>





            <div className="mt-4 d-flex align-items-center">
                <h1>{t('All Tools')}</h1>
            </div>
            <ul className="nav nav-tabs nav-tabs-none-bg mt-3">
                {tools.map((item, index) => (
                    <li key={index} className="nav-item">
                        <button
                            onClick={() => setTabIndex(index)}
                            className={`nav-link text-dark d-flex align-items-center ${tabIndex === index && "active"
                                }`}
                            aria-current="page"
                        >
                            <span> {t(item.title)}</span>
                            <span className="ms-2 badge rounded-pill fw-light">
                                {item.tools.length}
                            </span>
                        </button>
                    </li>
                ))}
            </ul>
            {auth?.user?.subscription ? (
                <div className="row mt-4 tools-list g-lg-4 g-3">
                    {tools[tabIndex].tools.map((item, index) => (
                        <React.Fragment key={index}>
                            {(isAltNameInTemplates(item.alt_name) || isAltNameInAddonTemplates(item.alt_name)) ? (
                                <ToolCard item={item} />
                            ) : (
                                <ToolCardDisable item={item} />
                            )}
                        </React.Fragment>
                    ))}
                </div>
            ) : (
                <div className="row mt-4 tools-list g-lg-4 g-3">
                    {tools[tabIndex].tools.map((item, index) => (
                        <React.Fragment key={index}>

                            {isAltNameInAddonTemplates(item.alt_name) ? (
                                <ToolCard item={item} />
                            ) : (
                                <ToolCardNoSubscription item={item} />

                            )}
                        </React.Fragment>
                    ))}
                </div>
            )}
        </>
    )
}

export default connect(({ auth }) => ({ auth }))(DashboardPage);
