import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Form from "react-bootstrap/Form";
import { Icon } from "@iconify/react";
import { useNavigate, useParams } from "react-router-dom";
import API from "../../helpers/Axios";
import { store } from "../../store/configureStore";
import { useTranslation } from "react-i18next";
import axios from "axios";
const GLOBAL = require('../../config/Global');

const SubscribeOfferPage = ({ auth }) => {
    const { id } = useParams();
    const linkTo = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [validated, setValidated] = useState(false);
    const [plan, setPlan] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { t, i18n } = useTranslation();
    const [isPaying, setIsPaying] = useState(false);

    useEffect(() => {

        function handleMessage(e) {
            const message = e.data; // Get the message data from the event

            // Check if the received message matches the expected content
            if (message && message.success === true) {
                setIsPaying(false);
                API.post('auth/user')
                    .then(res => {
                        store.dispatch({ type: 'UPDATE_USER_INFO', user: res.data });
                        setTimeout(() => {
                            linkTo(`/dashboard`);
                            window.location.reload();
                        }, 100);
                    });
            }
        }
        window.addEventListener('message', handleMessage);
        return () => {
            window.removeEventListener('message', handleMessage);
        };

        if (auth.isLogged == false) {
            linkTo(`/sign-in`);
        }

    }, []);

    const handleSubmit = (event) => {
        if (isSubmitting) {
            event.preventDefault();
            return
        }
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);

        const data = new FormData(event.target);
        setIsSubmitting(true);
        API.post('auth/login', data)
            .then(res => {
                setIsSubmitting(false);
                store.dispatch({ type: 'UPDATE_ACCESS_TOKEN', accessToken: res.data.access_token });
                setTimeout(() => {
                    API.post('auth/user')
                        .then(res => {
                            store.dispatch({ type: 'TOGGLE_AUTH', user: res.data });
                            setTimeout(() => {
                                linkTo(`/dashboard`);
                                window.location.reload();
                            }, 100);
                        });
                }, 100);
            }).catch(error => {
                console.log(error.response);
                setIsSubmitting(false);
            });
        event.preventDefault();
    };

    const [payments, setPayments] = useState(null);

    const getAvailablePayments = () => {
        axios.post(GLOBAL.API_URL.replace('/api', '') + '/available-payments', {
            'api-token': auth.accessToken
        }).then(function (res) {
            setPayments(res.data);
        }).catch(function (error) {
            console.log(error);
        });
    }

    const getPlanById = () => {
        API.post(`/plan/${id}`)
            .then(res => {
                setPlan(res.data);
                setIsLoading(false);
            }).catch(error => {
                console.log(error);
            });
    }



    function SaleCounter(props) {
        const { saleEnd } = props;
        const [timeLeft, setTimeLeft] = useState(saleEnd - Date.now());

        useEffect(() => {
            const intervalId = setInterval(() => {
                setTimeLeft(saleEnd - Date.now());
            }, 1000);

            return () => clearInterval(intervalId);
        }, [saleEnd]);

        const seconds = Math.floor((timeLeft / 1000) % 60);
        const minutes = Math.floor((timeLeft / 1000 / 60) % 60);
        const hours = Math.floor((timeLeft / 1000 / 60 / 60) % 24);
        const days = Math.floor(timeLeft / 1000 / 60 / 60 / 24);

        return (
            <div className="d-flex align-items-center justify-content-center counter-sale">
                <div className="d-block text-center">
                    {seconds}
                    <span className="d-block h6 fw-normal"> ثانية</span>
                </div>
                <span class="h2 m-2 fw-normal">|</span>
                <div className="d-block text-center">
                    {minutes}
                    <span className="d-block h6 fw-normal"> دقيقة</span>
                </div>
                <span class="h2 m-2 fw-normal">|</span>
                <div className="d-block text-center">
                    {hours}
                    <span className="d-block h6 fw-normal"> ساعة</span>
                </div>

                <span class="h2 m-2 fw-normal">|</span>


                <div className="d-block text-center">
                    {days}
                    <span className="d-block h6 fw-normal"> يوم</span>
                </div>
            </div>
        );
    }

    useEffect(() => {
        getAvailablePayments();
        getPlanById();
    }, []);



    const [plans, setPlans] = useState([]);
    const getPlans = () => {
        API.post('plans')
            .then(res => {
                setPlans(res.data);
            }).catch(error => {
                console.log(error);
            });
    }

    useEffect(() => {
        getPlans();

    }, []);

    const [showYearly, setShowYearly] = useState(false);


    const handleToggle = () => {
        if (plan.plan_period_format === 'M') {
            // Fetch Yearly plan when switching from Monthly to Yearly
            const yearlyPlan = plans.find(item => item.title === plan.title && item.plan_period_format === 'Y');
            if (yearlyPlan) {
                // Redirect to the new page with the yearly plan data
                // You can use React Router or any other navigation method here
                // Example using React Router:
                //window.location.href = `/subscribe/${yearlyPlan.id}`;
                linkTo(`/subscribe/${yearlyPlan.id}`);
                window.location.reload();


            }
        } else {
            // Fetch Monthly plan when switching from Yearly to Monthly
            const monthlyPlan = plans.find(item => item.title === plan.title && item.plan_period_format === 'M');
            if (monthlyPlan) {
                // Redirect to the new page with the monthly plan data
                // You can use React Router or any other navigation method here
                // Example using React Router:
                //history.push(`/subscribe/${monthlyPlan.id}`);
                //window.location.href = `/subscribe/${monthlyPlan.id}`;
                linkTo(`/subscribe/${monthlyPlan.id}`);
                window.location.reload();


            }
        }
    };


    useEffect(() => {
        // Set the initial state of showYearly based on the fetched plan
        setShowYearly(plan && plan.plan_period_format === 'Y');
    }, [plan]); // Re-run this effect whenever the 'plan' state changes



    return isLoading ? (
        <div className="w-100 vh-100 d-flex justify-content-center align-items-center">
            <div className="spinner-border ms-2" role="status">
                <span className="visually-hidden">{t('Loading...')}</span>
            </div>
        </div>
    ) : (
        <main className="bg-light d-flex justify-content-center align-items-center min-vh-100">
            <div className="container  py-2 py-md-5">
                <div className="row bg-white rounded overflow-hidden">
                    <div
                        style={{
                            backgroundImage: `url(${window.innerWidth <= 768 ? plan.bg_mobile_img : plan.bg_img})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'contain',
                            minHeight: '360px',
                        }}
                        className="col-lg-7 col-12 d-lg-flex align-items-center justify-content-center overflow-hidden"
                    >

                    </div>
                    <div className="col-lg-5 col-12 p-4 p-md-4">
                        <button className="btn btn-link px-0" onClick={() => linkTo(-1)}>
                            <Icon icon="material-symbols:arrow-back-rounded" width="24" className="text-secondary" />
                        </button>
                        <h2 className="mb-4">{plan.title}</h2>
                        <p className="mb-1">{plan.feature_slugin}</p>

                        <div className="h3 mt-2 d-md-flex align-items-start " ><SaleCounter saleEnd={new Date(plan.updated_at).getTime() + (30 * 24 * 60 * 60 * 1000)} /></div>


                        <hr className="my-1 mb-3" />
                        {auth.isLogged && (
                            <p className="mb-1">{t('Your account')}: {auth.user.email || auth.user.name}</p>
                        )}
                        <p className="">{t('Billing')}: {plan.plan_period_format === 'M' ? t('Monthly') : plan.plan_period + " " + t('Years')}</p>

                        {/* <div className="d-flex mt-3 mb-2">
                                <span class="fs-5 text-secondary fw-light">{plan.period + t('Year')}</span>
                            <div className="toggle-switch  mx-3">
                                <input
                                    type="checkbox"
                                    id="periodToggle"
                                    className="toggle-switch-checkbox"
                                    checked={showYearly}
                                    onChange={handleToggle} />
                                <label className="toggle-switch-label" htmlFor="periodToggle">
                                    <span className="toggle-switch-inner" />
                                    <span className="toggle-switch-switch" />
                                </label>
                            </div>
                            <span class="fs-5 text-secondary fw-light">{t("Monthly")}</span>
                        </div> */}


                        <div className="d-flex flex-column">
                            <small className="text-secondary mb-0">{t('Total charge:')}</small>
                            <h4 className="fw-bolder mb-0 mt-2">


                                <span>{window.CURRENCY_SYMBOL}{plan.price}
                                </span>

                            </h4>
                        </div>
                        <hr />
                        {/* <p className="fw-bold">{t('Choose payment method')}</p> */}
                        {isPaying && (
                            <div className="d-flex flex-column align-items-center justify-content-center mb-4">
                                <span className="spinner-border" role="status" aria-hidden="true"></span>
                            </div>
                        )}
                        <div className="row g-2">
                            {payments && Object.keys(payments).map((key, index) => (
                                <div className="col-6" key={index}>
                                    <a
                                        onClick={() => {
                                            setIsPaying(true);
                                            window.open(payments[key].subscriptionLink.replace(':id', id) + '?token=' + auth.accessToken, "_blank");
                                        }}
                                        target="_blank"
                                        style={{
                                            backgroundColor: payments[key].buttonColor
                                        }}
                                        className="btn btn-link text-decoration-none text-white d-flex align-items-center border-0 rounded w-100 px-3 py-3">
                                        <div
                                            dangerouslySetInnerHTML={{ __html: payments[key].buttonIcon }}
                                        />
                                        <span className="ms-3">{payments[key].name}</span>
                                    </a>

                                </div>
                            ))}
                        </div>
                        <hr className="mb-2" />
                        <small className="text-secondary"><Icon icon="material-symbols:lock-outline" /> {t('Your data is properly secured. We use SSL encryption and are PCI DSS-compliant')}</small>

                        <a onClick={(e) => {
                            linkTo(`/dashboard`);
                            e.preventDefault();
                        }}
                            href="/dashboard/" className="w-100 d-flex my-2">
                            <span className="mx-2">
                                {t('Skip Offer now')}
                            </span>

                        </a>

                    </div>

                </div>
            </div>
        </main>
    )
}

export default connect(({ auth }) => ({ auth }))(SubscribeOfferPage);
