import React from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { hexToRgbA } from "../helpers/Utils";
import { useTranslation } from "react-i18next";

const ToolCardNoSubscription = ({ item }) => {
    const linkTo = useNavigate();
    const { t } = useTranslation();

    return (
        <div className={`col-lg-3 col-12 card-container`}>
            <div className="h-100 card card-disable">
                <div className="card-content card-front p-3">
                    <div
                        className="d-flex align-items-center justify-content-between mb-3"
                        style={{ cursor: "pointer" }}
                    >
                        <div
                            className="card-tool-icon"
                            style={{ backgroundColor: hexToRgbA(item.icon_color) }}
                        >
                            <Icon icon={item.icon_name} style={{ color: item.icon_color }} width="26" />
                        </div>
                        <Icon icon="majesticons:lock" className="text-muted" />
                    </div>
                    <p className="fw-bold mb-1">{t(item.title)}</p>
                    <p className="mb-0 text-secondary">{t(item.description)}</p>
                </div>

                <div className="card-content card-back text-center text-white p-5">

                    {/* <p className="mb-1">{t("Subscribe to package to get the tool")}</p> */}
                    <button className="btn btn-primary mt-3 bg-white text-primary fw-bold mt-4" onClick={(e) => {
                        linkTo(`/dashboard/plans`);
                        e.preventDefault();
                    }}>
                        {t("Subscribe")}
                    </button>
                </div>

            </div>
        </div>
    );
};

export default ToolCardNoSubscription;


