import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Icon } from "@iconify/react/dist/iconify";
import API from "../../helpers/Axios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css'; // Import slick-carousel styles
import 'slick-carousel/slick/slick-theme.css'; // 
import tools from "../../helpers/tools.json";

const PlansPage = ({ auth }) => {
    const linkTo = useNavigate();
    const { t, i18n } = useTranslation();
    const [plans, setPlans] = useState([]);
    const getPlans = () => {
        API.post('plans')
            .then(res => {
                setPlans(res.data);
            }).catch(error => {
                console.log(error);
            });
    }

    useEffect(() => {
        getPlans();

    }, []);

    const [isOpen, setIsOpen] = useState(false);
    const [isOpenAll, setIsOpenAll] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    const toggleAccordionAll = () => {
        setIsOpenAll(!isOpenAll);
    };

    const [sliderHeight, setSliderHeight] = useState('auto');
    const sliderRef = useRef(null);

    const sliderSettings = {
        infinite: true, // Infinite loop
        slidesToShow: 1, // Number of slides to show at once
        slidesToScroll: 1, // Number of slides to scroll at a time
        speed: 500, // Transition speed (in milliseconds)
        arrows: true, // Show navigation arrows
        dots: true, // Hide slide indicators (dots) by default
        autoplay: true,
        autoplaySpeed: 15000, // Autoplay interval (in milliseconds)
        touchMove: true,
        pauseOnFocus: true,
        initialSlide: 0, // Set the initial slide to the first slide (0-based index)
        // adaptiveHeight: true,
        afterChange: (currentSlide) => {
            if (sliderRef.current) {
                updateSliderHeight(currentSlide);
            }
        },

    };

    useEffect(() => {
        // Initial height calculation when the component mounts
        if (sliderRef.current) {
            updateSliderHeight(0);
        }
    }, []);


    const updateSliderHeight = (currentSlide) => {
        // Get the heights of all slides
        const slideElements = sliderRef.current?.innerSlider.list;
        if (slideElements) {
            const slideHeights = Array.from(slideElements).map((slide) => slide.offsetHeight);

            // Find the maximum height among all slides
            const maxHeight = Math.max(...slideHeights);

            // Set the height of the slider container to the maximum slide height
            setSliderHeight(maxHeight);
        }
    };

    const hexToRgbA = (hex) => {
        var c;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length == 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
            return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ', .1)';
        }
        throw new Error('Bad Hex');
    }

    function SaleCounter(props) {
        const { saleEnd } = props;
        const [timeLeft, setTimeLeft] = useState(saleEnd - Date.now());

        useEffect(() => {
            const intervalId = setInterval(() => {
                setTimeLeft(saleEnd - Date.now());
            }, 1000);

            return () => clearInterval(intervalId);
        }, [saleEnd]);

        const seconds = Math.floor((timeLeft / 1000) % 60);
        const minutes = Math.floor((timeLeft / 1000 / 60) % 60);
        const hours = Math.floor((timeLeft / 1000 / 60 / 60) % 24);
        const days = Math.floor(timeLeft / 1000 / 60 / 60 / 24);

        return (
            <div dir="ltr">{`${days} D| ${hours} H| ${minutes} M| ${seconds} S`}</div>
        );
    }




    // const monthlyPlans = plans.filter(item => item.plan_period_format === 'M');
    // const yearlyPlans = plans.filter(item => item.plan_period_format === 'Y');


    const groupedPlans = plans.reduce((acc, item) => {
        const key = `${item.title}_${item.plan_period_format}`;
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(item);
        return acc;
    }, {});




    const [showYearly, setShowYearly] = useState(true);

    // Filter plans based on the selected period (monthly or yearly)
    const periodFilter = showYearly ? 'Y' : 'M';
    const filteredPlans = Object.values(groupedPlans)
        .filter(group => group.some(item => item.plan_period_format === periodFilter))
        .flat();

    const handleToggle = () => {
        setShowYearly(!showYearly);
    };

    return (

        <>
            <div id="pricing" class="text-center mt-5 mb-5">
                <p class="text-uppercase text-primary fw-bold mb-0">{t("Prices")}</p>
                <h1 class="fw-bolder">{t("10 times faster with Billenda AI")}</h1>
                <p class="fs-5 text-secondary fw-light">{t("3 Packages Tailored to Your Business Needs")}</p>
            </div><div className="container px-2">


                <div className="">
                    <div class="row d-flex align-items-center justify-content-center">

                        {filteredPlans.map((item, index) => (


                            <div className="item col-sm-4 col-xxl-3" key={index}>

                                <div>
                                    <div class="payment-card card mb-4  shadow-sm  py-4 px-3">
                                        {/* {item.sale_price != null && auth.user.have_sale == 1 && new Date(auth.user.sale_end) >
                                            Date.now() ? (
                                            <>
                                                <span
                                                    class="badge badge-inline badge-success bg-success text-light rounded-5">{t("Special Offer for a limited time")}
                                                </span>
                                                <div className="h3 badge mt-2 text-success">
                                                    <SaleCounter saleEnd={new Date(auth.user.sale_end).getTime()} />
                                                </div>
                                            </>

                                        ) : null} */}
                                        <div className="Payment-card-header">
                                            <div class="d-flex justify-content-start align-items-start">
                                                <div class="payment-card-title mb-2">{item.title}</div>
                                                {item.feature_slugin && (
                                                    <div class="mt-1 text-center bg-lighter-badge text-black p-1 badge fw-lighter"> {t(item.feature_slugin)}</div>
                                                )}
                                            </div>
                                            <span class="d-flex payment-card-description">{item.slugin_2}</span>

                                        </div>

                                        <div class="card-body p-0">
                                            <h2 class="payment-card-price mt-1 mb-0">

                                                {item.sale_price != null && (auth.user.have_sale == 1 && new Date(auth.user.sale_end) > Date.now() || !auth.isLogged) ? (

                                                    <><h2 class="d-flex price-old"> {item.plan_period_format === "Y"
                                                        ? Math.round(item.price / 12) // If plan_period_format is "Y", divide item.price by 12
                                                        : item.plan_period_format === "M"
                                                            ? Math.round(item.price) // If plan_period_format is "M", use item.price as is
                                                            : Math.round(item.price) // Handle other cases, use item.price as is (or perform a different calculation)
                                                    }
                                                        {t(' SAR')}
                                                    </h2><h2 class="d-flex justify-content-center align-items-center"> {item.plan_period_format === "Y"
                                                        ? Math.round(item.sale_price / 12) // If plan_period_format is "Y", divide item.price by 12
                                                        : item.plan_period_format === "M"
                                                            ? Math.round(item.sale_price) // If plan_period_format is "M", use item.price as is
                                                            : Math.round(item.sale_price) // Handle other cases, use item.price as is (or perform a different calculation)
                                                    }
                                                            <span
                                                                className="text-muted payment-card-currency ps-2">{t('SAR')}</span>
                                                            <small className="ps-2">{t('For Every month yearly')}</small>
                                                        </h2></>
                                                ) : (
                                                    <h2 class="d-flex justify-content-center align-items-center"> {item.plan_period_format === "Y"
                                                        ? Math.round(item.price / 12)  // If plan_period_format is "Y", divide item.price by 12
                                                        : item.plan_period_format === "M"
                                                            ? Math.round(item.price) // If plan_period_format is "M", use item.price as is
                                                            : Math.round(item.price) // Handle other cases, use item.price as is (or perform a different calculation)
                                                    }
                                                        <span
                                                            className="text-muted payment-card-currency ps-2">{t('SAR')}</span>
                                                        <small className="ps-2">{t('For Every month yearly')}</small>
                                                    </h2>
                                                )}


                                            </h2>

                                            <span class="payment-card-price mt-1 mb-0">

                                                {item.month_price != null && (
                                                    <span className="monthly-price">
                                                        <span>{t('SAR')} {Math.round(item.month_price)}</span>
                                                        {' '}
                                                        <small>{t('Paid Monthly')}</small>
                                                    </span>
                                                )}


                                            </span>

                                            {/* <div className="d-flex align-items-center justify-content-center">
                                                    <span class="fs-5 text-secondary fw-light mx-2">{t("Yearly")}</span>
                                                    <div className="toggle-switch">
                                                        <input
                                                            type="checkbox"
                                                            id="periodToggle"
                                                            className="toggle-switch-checkbox"
                                                            checked={showYearly}
                                                            onChange={handleToggle} />
                                                        <label className="toggle-switch-label" htmlFor="periodToggle">
                                                            <span className="toggle-switch-inner" />
                                                            <span className="toggle-switch-switch" />
                                                        </label>
                                                    </div>
                                                    <span class="fs-5 text-secondary fw-light mx-2">{t("Monthly")}</span>
                                                </div> */}

                                            <a onClick={(e) => {
                                                linkTo(auth.isLogged ? `/subscribe/${item.id}` : `/sign-up`);
                                                e.preventDefault();
                                            }}
                                                href={auth.isLogged ? `/subscribe/${item.id}` : `/sign-up`}
                                                type="button" className="w-100 btn btn-md btn btn-outline-primary d-flex align-items-center justify-content-center my-2 subscribe-btn">
                                                <span className="mx-2">
                                                    {auth.isLogged && auth.user.subscription &&
                                                        auth.user.subscription.service.id === item.id
                                                        ? t('Current (Renew)')
                                                        : (auth.isLogged)
                                                            ? ((auth.user.tokens >= 1 || auth.user.subscription) ? t('Upgrade subscription') :
                                                                t('Start Trial'))
                                                            : t('Try Now')}
                                                </span>
                                                <Icon icon="material-symbols:keyboard-backspace-outlined" width="24"
                                                    className="text-secondary" />
                                            </a>
                                            <h5 class="pt-3">{t('Best Features')}</h5>

                                            <ul class="p-0 m-0">
                                                {item.description.split('\n').map((text, iIndex) => (
                                                    <li key={iIndex} className="d-flex align-items-center mb-1">
                                                        <img src="/assets/images/check-mark-svgrepo-com.svg" alt={t('Billenda AI')} />
                                                        <span className="ms-2">{text}</span>
                                                    </li>
                                                ))}
                                            </ul>

                                            <>
                                                <h2 className="accordion-header d-md-none" id={`heading${index}`}>
                                                    <button className={`text-center d-block accordion-button fw-600 ${isOpen ? '' : 'collapsed'}`} onClick={toggleAccordion}>
                                                        {t('All Features')}
                                                        <Icon className="mx-2" icon="ep:arrow-down-bold" />
                                                    </button>
                                                </h2>
                                                {isOpen && (
                                                    <div className="accordion-collapse collapse show" aria-labelledby={`heading${index}`}>
                                                        <div className="accordion-body">
                                                            <ul class="list-items p-0 m-0 row">
                                                                {item.templates_names.split('\n').map((text, iIndex) => (
                                                                    <li key={iIndex} className="d-flex align-items-center col-lg-12">
                                                                        {text.trim() === "" ? (
                                                                            <span className="d-none d-md-block ms-2mb-2">{t(text)}</span>
                                                                        ) : (
                                                                            <>
                                                                                <img src="/assets/images/check-mark-svgrepo-com.svg" alt={t('Billenda AI')} />
                                                                                <span className="ms-3 mb-2 ">{t(text)}</span>
                                                                            </>
                                                                        )}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )}
                                            </>





                                            {/* {auth.user.have_sale === 0 && (
                                                    <><span className="d-block center-title golden-guarantee">{t('Golden guarantee')}</span>
                                                        <span className="d-block fw-light center-title">{t('Subscription cancelation free within 72 hours')}</span></>
                                                )} */}

                                        </div>


                                    </div>
                                </div>

                            </div>

                        ))}

                    </div>

                    <h2 className="d-none d-md-flex accordion-header mt-3 mb-5" id={`all-features`}>
                        <button className={`text-center d-block accordion-button fw-600 ${isOpenAll ? '' : 'collapsed'}`} onClick={toggleAccordionAll}>
                            {t('View All Features')}
                            <Icon className="mx-2" icon="ep:arrow-down-bold" />
                        </button>
                    </h2>

                </div>

                {isOpenAll && (


                    <div class="d-none d-md-flex align-items-center justify-content-center">
                        <div class="col-md-10 shopping-card py-5">
                            <div class="row p-0">
                                <div class="col-lg-3">

                                </div>

                                {filteredPlans.map((item, index) => (

                                    <div class="col-lg-3">
                                        <div class="Bloggers">
                                            <h2 class="mb-4">{item.title}</h2>
                                            <a onClick={(e) => {
                                                linkTo(auth.isLogged ? `/subscribe/${item.id}` : `/sign-up`);
                                                e.preventDefault();
                                            }}
                                                href={auth.isLogged ? `/subscribe/${item.id}` : `/sign-up`}
                                                type="button" className="btn btn-md btn btn-outline-primary my-2 px-4 subscribe-btn">
                                                <span className="mx-2">
                                                    {auth.isLogged && auth.user.subscription &&
                                                        auth.user.subscription.service.id === item.id
                                                        ? t('Current (Renew)')
                                                        : (auth.isLogged)
                                                            ? ((auth.user.tokens >= 1 || auth.user.subscription) ? t('Upgrade subscription') :
                                                                t('Start Trial'))
                                                            : t('Try Now')}
                                                </span>
                                                <Icon icon="material-symbols:keyboard-backspace-outlined" width="24"
                                                    className="text-secondary" />
                                            </a>
                                        </div>

                                    </div>
                                ))}


                            </div>

                            <div class="row ">
                                <div class="col-lg-3 p-0">
                                    <h1 class="fw-bolder pt-4 px-5">{t('Features')}</h1>
                                </div>


                            </div>

                            <div class="row p-0">
                                <div class="col-lg-3 p-0">
                                    <div class="py-2 px-5 f-16-nw">
                                        {t('Total Words')}
                                    </div>
                                    <div class="py-2 px-5 f-16-nw bg-gray">
                                        {t('Total Tools')}
                                    </div>
                                    <div class="py-2 px-5 f-16-nw">
                                        {t('languages')}
                                    </div>
                                </div>

                                {filteredPlans.map((item, index) => (

                                    <div className="col-lg-3 mx-auto p-0" key={index}>

                                        {item.description.split('\n').map((text, iIndex) => (
                                            <div key={iIndex} className={`py-2 px-5 f-16-nw text-center ${iIndex === 1 ? 'bg-gray' : ''}`}>
                                                <span className={iIndex === 1 ? 'number mx-1' : 'ms-2'}>{text}</span>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>


                            <div class="row">
                                <div class="col-lg-3 p-0">
                                    <h1 class="fw-bolder pt-4 px-5">{t('Tools')} </h1>
                                </div>


                            </div>


                            <div class="row p-0 d-flex align-items-start justify-content-start">
                                <div class="col-lg-3 p-0">
                                    {tools[0].tools.map((tool, my_index) => (
                                        <div key={my_index} className="py-2 px-5 f-14-nw">
                                            {t(tool.title)}
                                        </div>
                                    ))}
                                </div>



                                {filteredPlans.map((item, index) => (
                                    <div className="col-lg-3 mx-auto p-0" key={index}>
                                        {tools[0].tools.map((tool, my_index) => {
                                            const isToolIncluded = item.templates_names.split('\n').some(templateName => templateName.trim() === tool.title.trim());
                                            return (
                                                <div key={my_index} className="py-2 px-4 f-14-nw text-center">
                                                    {isToolIncluded && (
                                                        <img src="/assets/images/check-mark-svgrepo-com.svg" alt={t('Billenda AI')} />
                                                    )}
                                                </div>
                                            );

                                            return null; // Return null when isToolIncluded is false
                                        })}
                                    </div>
                                ))}

                                {/* {filteredPlans.map((item, index) => {
                                    const templateNames = item.templates_names.split('\n');
                                    const hasMatchingTool = templateNames.some(templateName => {
                                        const trimmedTemplateName = templateName.trim();
                                        return tools[0].tools.some(tool => tool.title.includes(trimmedTemplateName));
                                    });



                                    return (

                                        
                                        <div className={`col-lg-3 mx-auto p-0 ${hasMatchingTool ? 'matching-tool' : ''}`} key={index}>

                                            {hasMatchingTool ? (
                                                <div className="py-2 px-4 f-16 text-center">
                                                    <img src="/assets/images/check-mark-svgrepo-com.svg" alt={t('Billenda AI')} />
                                                </div>
                                            ) : (
                                                <div className="py-2 px-4 f-16 text-center">
                                                    {item.name}
                                                </div>
                                            )}
                                        </div>
                                    );
                                })} */}




                            </div>




                        </div>

                    </div>


                )}

            </div ></>
    );

}

export default connect(({ auth }) => ({ auth }))(PlansPage);
