import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import AllToolsPage from "./AllToolsPage";
import CommonToolPage from "./CommonToolPage";
import DashboardPage from "./DashboardPage";
import ArticleGeneratorPage from "./ArticleGeneratorPage";
import BlogWriterPage from "./BlogWriterPage";
import BlogWriterContentPage from "./BlogWriterContentPage";
import { Icon } from "@iconify/react";
import ContentReframerPage from "./ContentReframerPage";
import { Dropdown } from "react-bootstrap";
import { store } from "../../store/configureStore";
import ProjectModals from "./modals/ProjectsModal";
import BillingPage from "./BillingPage";
import SettingsPage from "./SettingsPage";
import PlansPage from "./PlansPage";
import MyContentPage from "./MyContentPage";
import { hexToRgbA } from "../../helpers/Utils";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import MyContentEditPage from "./MyContentEditPage";
import tools from "../../helpers/tools.json";
import UserAvatar from "../../components/UserAvatar";
import SubscribeBar from "../../components/SubscribeBar";
import ImageGeneratorPage from "./ImageGeneratorPage";
import API from "../../helpers/Axios";

const AccountToggle = React.forwardRef(({ children, onClick }, ref) => (
    <button
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
        className="btn border-0 p-0 d-flex align-items-center bg-link"
    >
        {children}
    </button>
));

const CreateContentToggle = React.forwardRef(({ children, onClick }, ref) => (
    <button
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
        className="btn btn-primary d-flex align-items-center"
    >
        {children}
    </button>
));

const UserIndexPage = ({ auth, display, common }) => {
    const { t } = useTranslation();
    const switchLanguage = (lang) => {
        fetch(`/assets/lang/${lang}.json`)
            .then((response) => response.json())
            .then((data) => {
                i18n.addResourceBundle(lang, 'translation', data);
            }).then(() => {
                i18n.changeLanguage(lang);
                store.dispatch({ type: 'SWITCH_LANGUAGE', lang: lang });
            });
    }
    const linkTo = useNavigate();
    const projectModalRef = useRef(null);
    const [isSideBarShown, setIsSideBarShown] = useState(false);
    const [engineTools, setEngineTools] = useState(tools);


    const [settings, setSettings] = useState([]);
    const getSettings = () => {
        API.post('settings')
            .then(res => {
                setSettings(res.data);
            }).catch(error => {
                console.log(error);
            });
    }

    useEffect(() => {
        getSettings();
    }, []);

    // Parse the "free_templates" array from the settings
    const freeTemplatesArray = JSON.parse(settings.free_templates || '[]');

    // Use the parsed array to select elements from tools[0].tools
    const popularTools = freeTemplatesArray.map(index => tools[0].tools[index]);





    const isAltNameInTemplates = (altName) => {
        return auth?.user?.subscription?.templates?.includes(altName);
    };

    // const isAltNameInAddonTemplates = (altName) => {
    //     const today = new Date();

    //     const addonTemplatesJSON = auth?.user?.addon_templates || '[]'; // Default to an empty array if it's not provided

    //     // Parse the JSON string into an array
    //     const addonTemplates = JSON.parse(addonTemplatesJSON);

    //     if (!Array.isArray(addonTemplates)) {
    //         // Handle the case where addon_templates is not an array
    //         return false; // or do something else
    //     }

    //     return addonTemplates.some((template) => {
    //         const templateDate = new Date(template.created_at);

    //         // Calculate the date one month from templateDate
    //         const oneMonthLater = new Date(templateDate);
    //         oneMonthLater.setMonth(oneMonthLater.getMonth() + 100);

    //         return (
    //             template.alt_name === altName &&
    //             templateDate < today && // Check if created_at is before today
    //             today <= oneMonthLater // Check if today is less than or equal to created_at + 1 month
    //         );
    //     });
    // };

    const isAltNameInAddonTemplates = (altName) => {
        const addonTemplatesJSON = auth?.user?.addon_templates || '[]'; // Default to an empty array if it's not provided

        // Parse the JSON string into an array
        const addonTemplates = JSON.parse(addonTemplatesJSON);

        if (!Array.isArray(addonTemplates)) {
            // Handle the case where addon_templates is not an array
            return false; // or do something else
        }

        return addonTemplates.some((template) => {
            return template.alt_name === altName && template.tokens > 0;
        });
    };


    const addonTemplates = JSON.parse(auth.user.addon_templates);

    // Map addonTemplates to their corresponding titles and alt_names
    const addonItems = addonTemplates.map((addon) => {
        // Find the matching tool in the tools array based on alt_name
        const matchingTool = tools[0].tools.find((tool) => tool.alt_name === addon.alt_name);

        // If a matching tool is found, return an object with title and alt_name, otherwise return a default value
        return matchingTool
            ? { title: matchingTool.title, icon_name: matchingTool.icon_name, alt_name: addon.alt_name }
            : { title: 'Title Not Found', alt_name: addon.alt_name };
    });

    function searchArrayByToolTitle(array, searchTerm) {
        return array.map(item => ({
            ...item,
            tools: item.tools.filter(tool => tool.title.toLowerCase().includes(searchTerm.toLowerCase()))
        })).filter(item => item.tools.length > 0);
    }


    const tokens = store.getState().common.tokens; // Replace with your actual tokens state


    const sidebarMenu = (title, iconString, link) => {
        return (
            <li className="nav-item">
                <a
                    onClick={(e) => {

                        // if (tokens < 1 && !auth.user.subscription) {
                        //     linkTo('/dashboard/plans'); // Redirect to '/dashboard/plans' if isRunning is false and tokens is less than 1
                        // } else {

                        linkTo(`${link}`);
                        if (link == '/dashboard') {
                            //window.location.reload();
                        }
                        // }

                        e.preventDefault();
                    }}
                    href={`${link}`}
                    className={`d-flex align-items-center ${pathName === link && 'active'}`} aria-current="page">
                    <Icon icon={iconString} width="24" />
                    <span className="menu-templates">{title}</span>
                </a>
            </li>
        )
    }

    const [pathName, setPathName] = useState('');

    useEffect(() => {
        setIsSideBarShown(false);
        setPathName(window.location.pathname);
    }, [window.location.pathname])

    return (
        <>
            <ProjectModals
                ref={projectModalRef}
            />
            <div className="w-100 min-vh-100 bg-light">
                <header className="dashboard-header w-100 position-sticky border-bottom bg-light">
                    <SubscribeBar />
                    <div className="w-100 px-lg-5 px-3 py-3">
                        <div className="d-flex flex-md-row align-items-center justify-content-between">
                            <button
                                onClick={() => {
                                    setIsSideBarShown(true)
                                }}
                                className="d-lg-none d-block border-0 bg-transparent">
                                <Icon icon="charm:menu-hamburger" width="32" className="text-secondary" />
                            </button>
                            <div className="d-lg-flex d-none align-items-center">
                                <a
                                    onClick={(e) => {
                                        if (tokens < 1 && !auth.user.subscription) {
                                            linkTo('/dashboard/plans'); // Redirect to '/dashboard/plans' if isRunning is false and tokens is less than 1
                                        } else {
                                            linkTo(`/dashboard`);
                                        }

                                        e.preventDefault();
                                    }}
                                    href={`/dashboard`}
                                    className="d-flex align-items-center text-decoration-none text-dark"
                                >
                                    <img width="110" height="36" src="/assets/images/logo.svg" alt={t('Billenda AI')} />
                                    {/* <span className="d-lg-block d-none fw-bolder ms-2">{t('Billenda AI')}</span> */}
                                </a>
                                <nav className="d-lg-inline-flex d-none ms-5 gap-4">
                                    <a
                                        onClick={(e) => {
                                            linkTo(`/dashboard/plans`);
                                            e.preventDefault();
                                        }}
                                        href={`/dashboard/plans`}
                                        className="me-3 py-2 text-dark text-decoration-none plans-btn">{t('Plans & Pricing')}</a>
                                </nav>
                            </div>
                            <nav className="d-flex align-content-center gap-3">
                                {(auth.user.subscription) && (<Dropdown align="end">
                                    <Dropdown.Toggle as={CreateContentToggle}>
                                        <Icon icon="mdi:plus-box-outline" width="24" />
                                        <span className="ms-2 d-lg-block d-none">{t('Create Content')}</span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdown-menu-tools">
                                        <div className="search-wrapper">
                                            <input
                                                className="form-control"
                                                placeholder="البحث..."
                                                onChange={(e) => {
                                                    const newArray = searchArrayByToolTitle(tools, e.target.value);
                                                    setEngineTools(prev => ([...newArray]));

                                                }}
                                            />
                                        </div>


                                        {auth?.user?.subscription ? (
                                            <>
                                                {engineTools.map((e) =>
                                                    e.tools.map((item, index) =>
                                                        isAltNameInTemplates(item.alt_name) || isAltNameInAddonTemplates(item.alt_name) ? (
                                                            <Dropdown.Item
                                                                key={index}
                                                                onClick={(e) => {
                                                                    linkTo(`/dashboard/tools/${item.alt_name}`);
                                                                    e.preventDefault();
                                                                }}
                                                                href={`/dashboard/tools/${item.alt_name}`}
                                                            >
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <div style={{ backgroundColor: hexToRgbA(item.icon_color) }} className="w-36px d-flex justify-content-center align-items-center rounded">
                                                                        <Icon icon={item.icon_name} style={{ color: item.icon_color }} width="16" />
                                                                    </div>
                                                                    <small className="flex-grow-1 ms-2 text-wrap">{t(item.title)}</small>
                                                                </div>
                                                            </Dropdown.Item>
                                                        ) : null
                                                    )
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                {engineTools.map((e) =>
                                                    e.tools.map((item, index) =>
                                                        isAltNameInTemplates(item.alt_name) || isAltNameInAddonTemplates(item.alt_name) ? (
                                                            isAltNameInAddonTemplates(item.alt_name) ? (
                                                                <Dropdown.Item
                                                                    key={index}
                                                                    onClick={(e) => {
                                                                        linkTo(`/dashboard/tools/${item.alt_name}`);
                                                                        e.preventDefault();
                                                                    }}
                                                                    href={`/dashboard/tools/${item.alt_name}`}
                                                                >
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <div style={{ backgroundColor: hexToRgbA(item.icon_color) }} className="w-36px d-flex justify-content-center align-items-center rounded">
                                                                            <Icon icon={item.icon_name} style={{ color: item.icon_color }} width="16" />
                                                                        </div>
                                                                        <small className="flex-grow-1 ms-2 text-wrap">{t(item.title)}</small>
                                                                    </div>
                                                                </Dropdown.Item>
                                                            ) : null
                                                        ) : null
                                                    )
                                                )}
                                            </>
                                        )}





                                    </Dropdown.Menu>
                                </Dropdown>
                                )}
                                <Dropdown
                                    className="ms-2"
                                >
                                    <Dropdown.Toggle
                                        className="btn-outline-primary bg-transparent border-0"
                                    >
                                        <img width="24" height="24" className="rounded" src={`/assets/flags/${display.language}.svg`} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>

                                        {[
                                            {
                                                name: 'Arabic',
                                                code: 'ar',
                                            },
                                            {
                                                name: 'English',
                                                code: 'en',
                                            }
                                        ].map((item, index) => (
                                            <Dropdown.Item
                                                onClick={() => {
                                                    switchLanguage(item.code)
                                                }}
                                                key={index}
                                                className="d-flex align-items-center py-2"
                                            >
                                                <img width="24" height="24" className="rounded" src={`/assets/flags/${item.code}.svg`} alt={item.name} />
                                                <small className="ms-2">{item.name}</small>
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Dropdown>
                                    <Dropdown.Toggle as={AccountToggle}>
                                        <div className="rounded-circle overflow-hidden">
                                            <UserAvatar
                                                name={auth.user.name}
                                                url={auth.user.artwork_url}
                                                width="38"
                                            />
                                        </div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <div className="d-flex align-items-center px-3 py-2">
                                            <div className="me-2 rounded-circle overflow-hidden">
                                                <UserAvatar
                                                    name={auth.user.name}
                                                    url={auth.user.artwork_url}
                                                    width="38"
                                                />
                                            </div>
                                            <div className="d-flex flex-column">
                                                <small className="text-dark fw-bold">{auth.user.name}</small>
                                                <div className="d-flex align-items-center">
                                                    <Icon icon="material-symbols:token-outline" width="20" className="text-warning" />
                                                    <span className="text-secondary fw-bold ms-1">{common.tokens}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="my-1" />
                                        {[
                                            {
                                                iconName: 'eos-icons:package-upgrade-outlined',
                                                title: t('Upgrade Account'),
                                                link: '/dashboard/plans'
                                            },
                                            ...(auth.user.subscription ? [
                                                {
                                                    iconName: 'material-symbols:credit-card-outline',
                                                    title: t('Billing & Plan'),
                                                    link: '/dashboard/billing'
                                                },
                                                {
                                                    iconName: 'carbon:settings',
                                                    title: t('Settings'),
                                                    link: '/dashboard/settings'
                                                }
                                            ] : [
                                                {
                                                    iconName: 'carbon:settings',
                                                    title: t('Settings'),
                                                    link: '/dashboard/settings'
                                                }
                                            ])
                                        ].map((item, index) => (
                                            <Dropdown.Item
                                                key={index}
                                                onClick={(e) => {
                                                    linkTo(item.link);
                                                    e.preventDefault();
                                                }}
                                                href={item.link}
                                            >
                                                <div className="d-flex align-items-center my-1">
                                                    <Icon icon={item.iconName} width="24" />
                                                    <span className="ms-3">{item.title}</span>
                                                </div>
                                            </Dropdown.Item>
                                        ))}
                                        <hr className="my-1" />
                                        <Dropdown.Item
                                            onClick={(e) => {
                                                // linkTo(`/`);
                                                store.dispatch({ type: 'UPDATE_ACCESS_TOKEN', accessToken: '' });
                                                store.dispatch({ type: 'TOGGLE_AUTH', user: {} });

                                                e.preventDefault();
                                                window.location.href = 'https://ai.billenda.com';
                                            }}
                                            href="/sign-out"
                                        >
                                            <div className="d-flex align-items-center my-1">
                                                <Icon icon="uil:signout" width="24" />
                                                <span className="ms-3">{t('Sign out')}</span>
                                            </div>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </nav>
                        </div>
                    </div>
                </header>
                <main className="dashboard-main px-lg-5 px-3 pb-5">
                    <div className="d-flex flex-nowrap">
                        {auth.user.name && (
                            <div className={`dashboard-main-sidebar pt-lg-4 ${isSideBarShown && 'm-shown'}`}>
                                <div className="h-100 d-flex flex-column justify-content-between">
                                    <div className="d-lg-none d-flex align-items-center justify-content-between mb-4">
                                        <a
                                            onClick={(e) => {
                                                if (tokens < 1 && !auth.user.subscription) {
                                                    linkTo('/dashboard/plans'); // Redirect to '/dashboard/plans' if isRunning is false and tokens is less than 1
                                                } else {
                                                    linkTo(`/dashboard`);
                                                }
                                                e.preventDefault();
                                            }}
                                            href={`/dashboard`}
                                            className="d-flex align-items-center text-decoration-none text-dark">
                                            <img width="110" height="36" src="/assets/images/logo.svg" alt={t('Billenda AI')} />
                                            {/* <span className="fw-bolder ms-2">Billenda AI</span> */}
                                        </a>
                                        <button
                                            className="border-0 bg-transparent"
                                            onClick={() => setIsSideBarShown(false)}
                                        >
                                            <Icon icon="ion:close" className="text-secondary" width="28" />
                                        </button>
                                    </div>
                                    <div className="d-flex flex-column align-items-center align-items-sm-start pt-2 flex-grow-1">
                                        <div className="d-flex align-items-center justify-content-between w-100 mb-2">
                                            <a
                                                onClick={(e) => {
                                                    linkTo(`/dashboard/billing`);
                                                    e.preventDefault();
                                                }}
                                                href={`/dashboard/billing`}
                                                className="fw-bold text-decoration-none text-secondary"
                                            >{auth.user.subscription ? auth.user.subscription.service.title : t('Free trial')}</a>
                                            {auth.user.subscription ?
                                                <small><span className="fw-bold">{common.tokens}</span> {t('words left')}</small>
                                                :
                                                <small><span className="fw-bold">
                                                    {auth.user.free_tokens ? JSON.parse(auth.user.free_tokens).reduce((acc, token) => acc + token, 0) : 0}
                                                </span> {t('Tries left')}</small>
                                            }


                                        </div>
                                        {auth.user.subscription ?
                                            <div className="progress w-100 progress-sm">
                                                <div className={`progress-bar ${(common.tokens / 1500) * 100 > 20 ? 'bg-success' : 'bg-danger'}`} role="progressbar" style={{ width: `${(common.tokens / 1500) * 100}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            :
                                            <div className="progress w-100 progress-sm">
                                                <div className={`progress-bar ${(common.tokens / 1500) * 100 > 20 ? 'bg-success' : 'bg-danger'}`} role="progressbar" style={{ width: `${(common.tokens / 1500) * 100}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        }

                                        {!auth.user.subscription && (
                                            <a
                                                onClick={(e) => {
                                                    linkTo(`/dashboard/plans`);
                                                    e.preventDefault();
                                                }}
                                                href={`/dashboard/plans`}
                                                className="w-100 btn btn-primary mt-3 plans-btn"
                                            >
                                                <Icon icon="mingcute:lightning-line" />
                                                <span>{(common.tokens > 1) ? t('Upgrade') : t('Start Trial')}</span>
                                            </a>
                                        )}
                                        <hr />
                                        <ul className="nav nav-pills nav-sidebar flex-column mb-auto w-100">
                                            {sidebarMenu(t('Dashboard'), 'iconoir:home-simple', '/dashboard')}
                                            {/* {sidebarMenu(t('All tools'), 'tabler:tools', '/dashboard/tools')} */}
                                            {sidebarMenu(t('My content'), 'ion:folder-outline', '/dashboard/my-content')}
                                            <hr />
                                            {popularTools.map((item, index) => {
                                                const freeTokens = JSON.parse(auth.user.free_tokens);

                                                const freeTokensValue = freeTokens[index];
                                                if (auth.user.subscription) {
                                                    if ((isAltNameInTemplates(item.alt_name) || freeTokensValue > 0) && !isAltNameInAddonTemplates(item.alt_name)) {
                                                        return (
                                                            <>
                                                                {sidebarMenu(t(item.title), '', `/dashboard/tools/${item.alt_name}`)}
                                                            </>
                                                        );
                                                    }
                                                } else {
                                                    if (freeTokensValue > 0 && !isAltNameInAddonTemplates(item.alt_name)) {
                                                        return (
                                                            <>
                                                                {sidebarMenu(t(item.title), '', `/dashboard/tools/${item.alt_name}`)}
                                                            </>
                                                        );
                                                    }
                                                }
                                            })}

                                            {addonItems.map((item, index) => (
                                                <>
                                                    {sidebarMenu(t(item.title), '', `/dashboard/tools/${item.alt_name}`)}
                                                </>

                                            ))}




                                            {/* {sidebarMenu(t('Article Composer'), 'majesticons:article-line', '/dashboard/tools/article-composer')}
                                            {sidebarMenu(t('Content Rewrite'), 'majesticons:repeat-circle-line', '/dashboard/tools/content-rewrite')} */}
                                            {/* {sidebarMenu(t('Image Generator'), 'ph:image-square-fill', '/dashboard/tools/image-generator')} */}
                                            {/* <li className="nav-item">
                                            <a
                                                style={{
                                                    cursor: "pointer"
                                                }}
                                                onClick={() => {
                                                    store.dispatch({ type: 'TOGGLE_DARK_MODE' })
                                                }}
                                                className={`d-flex align-items-center`}>
                                                <Icon icon={display.darkMode ? 'ic:outline-light-mode' : 'ic:outline-dark-mode'} width="24" />
                                                <span className="ms-2">{display.darkMode ? t('Light Mode') : t('Dark Mode')}</span>
                                            </a>
                                        </li> */}
                                        </ul>
                                        <hr />
                                    </div>
                                    <div className="card m-lg-3 mb-0">
                                        <button
                                            onClick={() => projectModalRef.current?.setShowProject(true)}
                                            className="p-0 px-3 my-2 border-0 bg-transparent d-flex align-items-center justify-content-between">
                                            <div className="d-flex flex-column align-items-start">
                                                <small className="text-muted text-uppercase smaller">{t('Project')}</small>
                                                <small className="fw-bold">{common.currentProject ? common.currentProject.name : t('Personal Folder')}</small>
                                            </div>
                                            <Icon icon="uil:sort" width="24" className="text-muted" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className={`pt-4 w-100 ${auth.user.name ? 'dashboard-main-routes' : ''}`}>
                            <Routes>
                                <Route exact path="/" element={<DashboardPage />} />
                                <Route exact path="/billing" element={<BillingPage />} />
                                <Route exact path="/settings" element={<SettingsPage />} />
                                <Route exact path="/plans" element={<PlansPage />} />
                                <Route exact path="/my-content" element={<MyContentPage />} />
                                <Route exact path="/my-content/:slug/:id" element={<MyContentEditPage />} />
                                <Route exact path="/tools" element={<AllToolsPage />} />
                                <Route exact path="/tools/article-composer/:id?" element={<ArticleGeneratorPage />} />
                                <Route exact path="/tools/blog-writing-assistant" element={<BlogWriterPage />} />
                                <Route exact path="/tools/blog-writing-assistant/content" element={<BlogWriterContentPage />} />
                                <Route exact path="/tools/content-rewrite" element={<ContentReframerPage />} />
                                <Route exact path="/tools/image-generator" element={<ImageGeneratorPage />} />
                                <Route exact path="/tools/:slug" element={<CommonToolPage />} />
                            </Routes>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default connect(({ auth, display, common }) => ({ auth, display, common }))(UserIndexPage);
