import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Form from "react-bootstrap/Form";
import { Icon } from "@iconify/react";
import { useNavigate, useParams } from "react-router-dom";
import API from "../../helpers/Axios";
import { store } from "../../store/configureStore";
import { useTranslation } from "react-i18next";
import axios from "axios";
const GLOBAL = require('../../config/Global');

const SubscribePage = ({ auth }) => {
    const { id } = useParams();
    const linkTo = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [validated, setValidated] = useState(false);
    const [plan, setPlan] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { t, i18n } = useTranslation();
    const [isPaying, setIsPaying] = useState(false);

    useEffect(() => {
        function handleMessage(e) {
            const message = e.data; // Get the message data from the event

            // Check if the received message matches the expected content
            if (message && message.success === true) {
                setIsPaying(false);
                API.post('auth/user')
                    .then(res => {
                        store.dispatch({ type: 'UPDATE_USER_INFO', user: res.data });
                        setTimeout(() => {
                            linkTo(`/dashboard`);
                            window.location.reload();
                        }, 100);
                    });
            }
        }
        window.addEventListener('message', handleMessage);
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    const handleSubmit = (event) => {
        if (isSubmitting) {
            event.preventDefault();
            return
        }
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);

        const data = new FormData(event.target);
        setIsSubmitting(true);
        API.post('auth/login', data)
            .then(res => {
                setIsSubmitting(false);
                store.dispatch({ type: 'UPDATE_ACCESS_TOKEN', accessToken: res.data.access_token });
                setTimeout(() => {
                    API.post('auth/user')
                        .then(res => {
                            store.dispatch({ type: 'TOGGLE_AUTH', user: res.data });
                            setTimeout(() => {
                                linkTo(`/dashboard`);
                                window.location.reload();
                            }, 100);
                        });
                }, 100);
            }).catch(error => {
                console.log(error.response);
                setIsSubmitting(false);
            });
        event.preventDefault();
    };

    const [payments, setPayments] = useState(null);

    const getAvailablePayments = () => {
        axios.post(GLOBAL.API_URL.replace('/api', '') + '/available-payments', {
            'api-token': auth.accessToken
        }).then(function (res) {
            setPayments(res.data);
        }).catch(function (error) {
            console.log(error);
        });
    }

    const getPlanById = () => {
        API.post(`/plan/${id}`)
            .then(res => {
                setPlan(res.data);
                setIsLoading(false);
            }).catch(error => {
                console.log(error);
            });
    }


    useEffect(() => {
        getAvailablePayments();
        getPlanById();
    }, []);



    const [plans, setPlans] = useState([]);
    const getPlans = () => {
        API.post('plans')
            .then(res => {
                setPlans(res.data);
            }).catch(error => {
                console.log(error);
            });
    }

    useEffect(() => {
        getPlans();

    }, []);

    const [showYearly, setShowYearly] = useState(false);


    const handleToggle = () => {
        if (plan.plan_period_format === 'M') {
            // Fetch Yearly plan when switching from Monthly to Yearly
            const yearlyPlan = plans.find(item => item.title === plan.title && item.plan_period_format === 'Y');
            if (yearlyPlan) {
                // Redirect to the new page with the yearly plan data
                // You can use React Router or any other navigation method here
                // Example using React Router:
                //window.location.href = `/subscribe/${yearlyPlan.id}`;
                linkTo(`/subscribe/${yearlyPlan.id}`);
                window.location.reload();


            }
        } else {
            // Fetch Monthly plan when switching from Yearly to Monthly
            const monthlyPlan = plans.find(item => item.title === plan.title && item.plan_period_format === 'M');
            if (monthlyPlan) {
                // Redirect to the new page with the monthly plan data
                // You can use React Router or any other navigation method here
                // Example using React Router:
                //history.push(`/subscribe/${monthlyPlan.id}`);
                //window.location.href = `/subscribe/${monthlyPlan.id}`;
                linkTo(`/subscribe/${monthlyPlan.id}`);
                window.location.reload();


            }
        }
    };


    useEffect(() => {
        // Set the initial state of showYearly based on the fetched plan
        setShowYearly(plan && plan.plan_period_format === 'Y');
    }, [plan]); // Re-run this effect whenever the 'plan' state changes



    return isLoading ? (
        <div className="w-100 vh-100 d-flex justify-content-center align-items-center">
            <div className="spinner-border ms-2" role="status">
                <span className="visually-hidden">{t('Loading...')}</span>
            </div>
        </div>
    ) : (
        <main className="bg-light d-flex justify-content-center align-items-center min-vh-100">
            <div className="container py-5">
                <div className="row bg-white rounded overflow-hidden">
                    <div className="col-lg-5 col-12 p-3 p-lg-5">
                        <button className="btn btn-link px-0" onClick={() => linkTo(-1)}>
                            <Icon icon="material-symbols:arrow-back-rounded" width="24" className="text-secondary" />
                        </button>
                        <h2 className="mb-4">{plan.title}</h2>
                        <hr className="my-4" />
                        {auth.isLogged && (
                            <p className="mb-1">{t('Your account')}: {auth.user.email || auth.user.name}</p>
                        )}
                        <p className="">{t('Billing')}: {plan.plan_period_format === 'M' ? t('Monthly') : t('Yearly')}</p>

                        <div className="d-flex mt-3 mb-2">
                            <span class="fs-5 text-secondary fw-light">{t("Yearly")}</span>
                            <div className="toggle-switch  mx-3">
                                <input
                                    type="checkbox"
                                    id="periodToggle"
                                    className="toggle-switch-checkbox"
                                    checked={showYearly}
                                    onChange={handleToggle} />
                                <label className="toggle-switch-label" htmlFor="periodToggle">
                                    <span className="toggle-switch-inner" />
                                    <span className="toggle-switch-switch" />
                                </label>
                            </div>
                            <span class="fs-5 text-secondary fw-light">{t("Monthly")}</span>
                        </div>


                        <div className="d-flex flex-column">
                            <small className="text-secondary mb-0">{t('Total charge:')}</small>
                            <h4 className="fw-bolder mb-0">

                                {plan.sale_price != null && auth.user.have_sale == 1 && new Date(auth.user.sale_end) > Date.now() ? (
                                    <span>
                                        <span className="text-muted h5 price-old">{window.CURRENCY_SYMBOL}{plan.price}</span>
                                        {' '}
                                        {window.CURRENCY_SYMBOL}{plan.sale_price}
                                    </span>
                                ) : (
                                    <span>{window.CURRENCY_SYMBOL}{plan.price}
                                    </span>
                                )}

                            </h4>
                        </div>
                        <hr />
                        {/* <p className="fw-bold">{t('Choose payment method')}</p> */}
                        {isPaying && (
                            <div className="d-flex flex-column align-items-center justify-content-center mb-4">
                                <span className="spinner-border" role="status" aria-hidden="true"></span>
                            </div>
                        )}
                        <div className="row g-2">
                            {payments && Object.keys(payments).map((key, index) => (
                                <div className="col-6" key={index}>
                                    <a
                                        onClick={() => {
                                            setIsPaying(true);
                                            window.open(payments[key].subscriptionLink.replace(':id', id) + '?token=' + auth.accessToken, "_blank");
                                        }}
                                        target="_blank"
                                        style={{
                                            backgroundColor: payments[key].buttonColor
                                        }}
                                        className="btn btn-link text-decoration-none text-white d-flex align-items-center border-0 rounded w-100 px-3 py-3">
                                        <div
                                            dangerouslySetInnerHTML={{ __html: payments[key].buttonIcon }}
                                        />
                                        <span className="ms-3">{payments[key].name}</span>
                                    </a>

                                </div>
                            ))}
                        </div>
                        <hr className="mb-2" />
                        <small className="text-secondary"><Icon icon="material-symbols:lock-outline" /> {t('Your data is properly secured. We use SSL encryption and are PCI DSS-compliant')}</small>
                    </div>
                    <div style={{ backgroundColor: '#f9f9f9' }} className="col-lg-7 col-12 d-none d-lg-flex align-items-center justify-content-center overflow-hidden">
                        <img width="200" height="60" src="/assets/images/logo.svg" alt={t('Billenda AI')} />

                    </div>
                </div>
            </div>
        </main>
    )
}

export default connect(({ auth }) => ({ auth }))(SubscribePage);
