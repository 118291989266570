import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ToolCard from "../../components/ToolCard";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import LanguagesForm from "../../components/LanguagesForm";
import { useLocation, useParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import { copyText, hexToRgbA } from "../../helpers/Utils";
import API from "../../helpers/Axios";
import { store } from "../../store/configureStore";
import { useTranslation } from "react-i18next";
import CopyButton from "../../components/CopyButton";
import tools from "../../helpers/tools.json";
import { Base64 } from "js-base64";
import { NewtonsCradle } from '@uiball/loaders';
import { useNavigate } from "react-router-dom";
import Timer from '../../components/Timer'; // Import the Timer component


const findSection = (pathName) => {
    for (const e of tools) {
        const tool = e.tools.find(tool => tool.alt_name === pathName);
        if (tool) {
            return tool;
        }
    }
    return null;
}

const CommonToolPage = ({ navigation, route, auth }) => {
    const { t } = useTranslation();
    const linkTo = useNavigate();
    const [tabIndex, setTabIndex] = useState(0);
    const { slug } = useParams();
    const location = useLocation();
    const [pathName, setPathName] = useState(location.pathname.replace('/dashboard/tools/', ''));

    const [shouldExit, setShouldExit] = useState(false);


    const [settings, setSettings] = useState([]);
    let trailEnds = 0;
    const isAltNameInTemplates = (altName) => {
        return auth?.user?.subscription?.templates?.includes(altName);
    };

    const isAltNameInFreeTemplates = (free_templates_names, altName) => {
        return free_templates_names.includes(altName);
    };


    const isAltNameInAddonTemplates = (altName) => {
        const today = new Date();

        const addonTemplatesJSON = auth?.user?.addon_templates || '[]'; // Default to an empty array if it's not provided

        // Parse the JSON string into an array
        const addonTemplates = JSON.parse(addonTemplatesJSON);

        if (!Array.isArray(addonTemplates)) {
            // Handle the case where addon_templates is not an array
            return false; // or do something else
        }

        return addonTemplates.some((template) => {
            const templateDate = new Date(template.created_at);

            // Calculate the date one month from templateDate
            const oneMonthLater = new Date(templateDate);
            oneMonthLater.setMonth(oneMonthLater.getMonth() + 100);

            return (
                template.alt_name === altName &&
                templateDate < today && // Check if created_at is before today
                today <= oneMonthLater // Check if today is less than or equal to created_at + 1 month
            );
        });
    };






    useEffect(() => {
        setPathName(location.pathname.replace('/dashboard/tools/', ''));
    }, [slug])



    const [isLoading, setIsLoading] = useState(true);

    const sectionObject = findSection(pathName);






    const [validated, setValidated] = useState(false);

    const [textAreaValue, setTextAreaValue] = useState('');

    const initialVariants = auth.user.subscription ? 2 : 2;

    // Use 'initialVariants' to set the state
    const [variants, setVariants] = useState(initialVariants);
    const [variantsContent, setVariantsContent] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [savedIndexes, setSavedIndexes] = useState([]);
    const [savedContents, setSavedContents] = useState([]);
    const [deletingId, setDeletingId] = useState(null);

    useEffect(() => {
        if (variants < 1) {
            setVariants(1);
        }
        if (variants > 3) {
            setVariants(3);
        }
    }, [variants]);


    const getSettings = () => {
        API.post('settings')
            .then(res => {
                setSettings(res.data);


                // You can place the useEffect here
                // Make sure to include the necessary dependencies in the dependency array
                // This example assumes that sectionObject.alt_name is one of the dependencies
                // Adjust it as needed based on your specific requirements.
                console.log('settings.free_templates_names:', res.data.free_templates_names);
                if (res.data.free_templates_names) {
                    const freeTemplatesNamesArray = JSON.parse(res.data.free_templates_names);

                    if (!auth.user.subscription) {
                        if (isAltNameInAddonTemplates(sectionObject.alt_name)) {
                            setShouldExit(true); // Set the flag to true to exit the useEffect
                        } else if (!freeTemplatesNamesArray.includes(sectionObject.alt_name)) {
                            linkTo('/dashboard/plans');
                        }
                    } else if (isAltNameInTemplates(sectionObject.alt_name) || isAltNameInAddonTemplates(sectionObject.alt_name)) {
                        setShouldExit(true); // Set the flag to true to exit the useEffect
                    } else if (!freeTemplatesNamesArray.includes(sectionObject.alt_name)) {
                        linkTo('/dashboard/plans');
                    }
                }





            }).catch(error => {
                console.log(error);
            });
    }

    useEffect(() => {
        getSettings();
    }, []);



    const [processes, setProcesses] = useState([]);
    const [isRunning, setIsRunning] = useState(false);


    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {

            const tokens = store.getState().common.tokens; // Replace with your actual tokens state

            if (tokens < 1) {
                store.dispatch({ type: 'UPDATE_TOKENS', tokens: store.getState().common.tokens + 10 });
                linkTo('/dashboard'); // Redirect to '/dashboard/plans' if isRunning is false and tokens is less than 1

            } else if (trailEnds == 1) {
                trailEnds = 0;
                linkTo('/dashboard');

            } else {

                const data = new FormData(event.target);
                setProcesses(Array.from({ length: variants }, (_, i) => i));
                runProcess(Array.from({ length: variants }, (_, i) => i), data);
                setVariantsContent(Array.from({ length: variants }, (_, i) => ''));
                setSavedIndexes([]);
                setTabIndex(0);

            }
        }
        setValidated(true);
        event.preventDefault();
        event.stopPropagation();
    };

    const runProcess = async (processes, data) => {
        if (processes.length === 0) {
            setIsRunning(false);
            return;
        }

        let builtString = '';

        for (const [name, value] of data.entries()) {
            console.log(name, value);
            if (name !== 'language' && name !== 'creativity') {
                builtString += name + '=' + value + ' & ';
            }
        }

        setIsRunning(true);
        const currentProcess = processes[0];
        try {
            console.log(`Running process: ${currentProcess}`);

            await new Promise(resolve => {
                API.post('auth/user/text-generator', {
                    action: Base64.encode(`write ${slug.replace(/-/g, ' ')} with data --> ${builtString.replace(/_/g, ' ')}`),
                    currentProcess: currentProcess,
                }).then((res) => {
                    if (res.data && res.data.usage) {
                        store.dispatch({ type: 'UPDATE_TOKENS', tokens: store.getState().common.tokens - res.data.usage });
                        if (res.data.trails_end == 1) {
                            store.dispatch({ type: 'UPDATE_TOKENS', tokens: 0 });
                        }
                    }
                    if (res.data.trails_end == 1) {
                        trailEnds = 1;
                        store.dispatch({ type: 'UPDATE_TOKENS', tokens: 0 });

                    }
                    setVariantsContent((prev) => {
                        const newArray = [...prev];
                        newArray[currentProcess] = res.data.text.replace(/^\s+|\s+$/g, '').split("\n").map(paragraph => `<p>${paragraph}</p>`).join('');
                        return newArray;
                    });
                    resolve(true);
                }).catch(error => {
                    resolve(true);

                });
            });
        } catch (error) {
            console.error(`Error running process: ${currentProcess}`);
        }
        setProcesses(processes.slice(1));
        await runProcess(processes.slice(1), data);
    };

    const saveContent = (text, index) => {
        API.post('auth/content/save', {
            type: slug,
            content: text
        }).then(res => {
            setSavedIndexes([...savedIndexes, index])
        }).catch(error => {

        });
    }

    const getSavedContents = () => {
        API.post('auth/contents', {
            type: slug,
        }).then(res => {
            setSavedContents(res.data.data)
        }).catch(error => {

        });
    }

    const deleteContent = (id, index) => {
        setSavedContents((prev) => [
            ...prev.slice(0, index),
            ...prev.slice(index + 1)
        ]);
        API.post('auth/content/delete', {
            id: deletingId,
        }).then(res => {

        }).catch(error => {

        });
    }

    useEffect(() => {
        getSavedContents();
    }, [savedIndexes]);


    return (
        <div className="row">
            <div className="col-lg-4 col-12">
                <div className="card p-3">
                    <div className="d-flex align-items-center mb-3">
                        <div className="card-tool-icon" style={{ backgroundColor: hexToRgbA(sectionObject.icon_color) }}>
                            <Icon icon={sectionObject.icon_name} style={{ color: sectionObject.icon_color }} width="24" />
                        </div>
                        <span className="ms-2 fw-bold">{t(sectionObject.title)}</span>
                    </div>
                    <p className="text-secondary">{t(sectionObject.description)}</p>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label>{t('Language')}</Form.Label>
                                <LanguagesForm />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>{t('Creativity')}</Form.Label>
                                <Form.Select name="creativity">
                                    <option value="high">{t('High')}</option>
                                    <option value="regular">{t('Regular')}</option>
                                </Form.Select>
                            </Form.Group>
                        </Row>
                        {(pathName === 'ask-ai') && (
                            <>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('Ask Ai to write any thing')}</Form.Label>
                                    <Form.Control
                                        name="about"
                                        required
                                        as="textarea"
                                        type="text"
                                        placeholder={t('let me know about... / Write information about the largest company capital in the world')}
                                    />
                                </Form.Group>
                            </>
                        )}
                        {(pathName === 'blog-section-author' || pathName === 'paragraph-writer-for-blogs') && (
                            <>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('Title of your blog article')}</Form.Label>
                                    <Form.Control
                                        name="blog_title"
                                        required
                                        as="textarea"
                                        type="text"
                                        placeholder={t('5 ways to boost your sales with copywriting')}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('Subheading / Bullet Point')}</Form.Label>
                                    <Form.Control
                                        name="subheading"
                                        required
                                        as="textarea"
                                        type="text"
                                        placeholder={t('Example: e-store owners')}
                                    />
                                </Form.Group>
                            </>
                        )}
                        {(pathName === 'blog-introduction-maker' || pathName === 'blog-outline-tool') && (
                            <>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('Title of your blog article')}</Form.Label>
                                    <Form.Control
                                        name="article_title"
                                        required
                                        as="textarea"
                                        type="text"
                                        placeholder={t('5 ways to boost your sales with copywriting')}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <Form.Label>{t('What is your blog post about?')}</Form.Label>
                                        <small className="text-muted">{textAreaValue.length}/200</small>
                                    </div>
                                    <Form.Control
                                        name="post_content"
                                        required
                                        as="textarea"
                                        type="text"
                                        placeholder={t('a blog article about the best tools to increase your website traffic')}
                                        rows="9"
                                        maxLength={500}
                                        value={textAreaValue}
                                        onChange={(e) => {
                                            setTextAreaValue(e.currentTarget.value)
                                        }}
                                    />
                                </Form.Group>
                            </>
                        )}
                        {(pathName === 'blog-conclusion-writer') && (
                            <>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('Article title or topic')}</Form.Label>
                                    <Form.Control
                                        name="topic"
                                        required
                                        as="textarea"
                                        type="text"
                                        placeholder="{t('Example: 5 marketing methods through content')}"
                                    />
                                </Form.Group>
                            </>
                        )}
                        {(pathName === 'paragraph-creator') && (
                            <>
                                <Form.Group className="mb-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <Form.Label>{t('What is your paragraph about?')}</Form.Label>
                                        <small className="text-muted">{textAreaValue.length}/1200</small>
                                    </div>
                                    <Form.Control
                                        name="paragraph_content"
                                        required
                                        as="textarea"
                                        type="text"
                                        placeholder={t('The best cryptocurrency to invest in')}
                                        rows="9"
                                        maxLength={500}
                                        value={textAreaValue}
                                        onChange={(e) => {
                                            setTextAreaValue(e.currentTarget.value)
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('Keyword to include')}</Form.Label>
                                    <Form.Control
                                        name="include_keyword"
                                        required
                                        placeholder={t('Cryptocurrencies')}
                                        rows="4"
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('Tone of voice')}</Form.Label>
                                    <Form.Select name="tone_of_voice">
                                        <option value="professional">{t('Professional')}</option>
                                        <option value="childish">{t('Childish')}</option>
                                        <option value="luxurious">{t('Luxurious')}</option>
                                        <option value="friendly">{t('Friendly')}</option>
                                        <option value="confident">{t('Confident')}</option>
                                        <option value="exciting">{t('Exciting')}</option>
                                    </Form.Select>
                                </Form.Group>
                            </>
                        )}
                        {(pathName === 'engaging-blog-titles' || pathName === 'blog-inspiration-generator' || pathName === 'listicles-blog-titles') && (
                            <>
                                <Form.Group className="mb-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <Form.Label>{t('What is your blog post about?')}</Form.Label>
                                        <small className="text-muted">{textAreaValue.length}/200</small>
                                    </div>
                                    <Form.Control
                                        name="post_content"
                                        required
                                        as="textarea"
                                        type="text"
                                        placeholder={t('a blog article about the best tools to increase your website traffic')}
                                        rows="9"
                                        maxLength={500}
                                        value={textAreaValue}
                                        onChange={(e) => {
                                            setTextAreaValue(e.currentTarget.value)
                                        }}
                                    />
                                </Form.Group>
                            </>
                        )}
                        {(pathName === 'talk-points-generator') && (
                            <>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('Article title')}</Form.Label>
                                    <Form.Control
                                        name="article_title"
                                        required
                                        as="textarea"
                                        type="text"
                                        placeholder={t('5 ways to boost your sales with copywriting')}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('Subheading')}</Form.Label>
                                    <Form.Control
                                        required
                                        as="textarea"
                                        type="text"
                                        placeholder={t('Why you should use an AI writer')}
                                    />
                                </Form.Group>
                            </>
                        )}
                        {['pros-cons-maker', 'generate-startup-names'].includes(pathName) && (
                            <>
                                {pathName === 'pros-cons-maker' && (
                                    <Form.Group className="mb-3">
                                        <Form.Label>{t('Product Name')}</Form.Label>
                                        <Form.Control
                                            name="product_name"
                                            required
                                            placeholder={t('Product Name')}
                                            rows="4"
                                        />
                                    </Form.Group>
                                )}
                                {pathName === 'generate-startup-names' && (
                                    <Form.Group className="mb-3">
                                        <Form.Label>{t('Seed Words')}</Form.Label>
                                        <Form.Control
                                            name="seed_words"
                                            required
                                            placeholder={t('Remote training courses')}
                                            rows="4"
                                        />
                                    </Form.Group>
                                )}
                                <Form.Group className="mb-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <Form.Label>{t('Product Description?')}</Form.Label>
                                        <small className="text-muted">{textAreaValue.length}/1200</small>
                                    </div>
                                    <Form.Control
                                        name="product_description"
                                        required
                                        as="textarea"
                                        type="text"
                                        placeholder={t('Explain here to the AI what your product (or service) is about. Rewrite to get different results.')}
                                        rows="9"
                                        maxLength={500}
                                        value={textAreaValue}
                                        onChange={(e) => {
                                            setTextAreaValue(e.currentTarget.value)
                                        }}
                                    />
                                </Form.Group>
                            </>
                        )}

                        {(['web-page-headlines', 'subtitles-for-websites']).includes(pathName) && (
                            <>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('Tone of voice')}</Form.Label>
                                    <Form.Select name="tone_of_voice">
                                        <option value="professional">{t('Professional')}</option>
                                        <option value="childish">{t('Childish')}</option>
                                        <option value="luxurious">{t('Luxurious')}</option>
                                        <option value="friendly">{t('Friendly')}</option>
                                        <option value="confident">{t('Confident')}</option>
                                        <option value="exciting">{t('Exciting')}</option>
                                    </Form.Select>
                                </Form.Group>
                                {/* )} */}
                                <Row className="mb-3">
                                    {(pathName !== 'user-testimonials-reviews') && (
                                        <Form.Group as={Col}>
                                            <Form.Label>{t('Audience')}</Form.Label>
                                            <Form.Control
                                                name="audience"
                                                required
                                                type="text"
                                                placeholder={t('women')}
                                            />
                                        </Form.Group>
                                    )}
                                    <Form.Group as={Col} controlId="validationCustom02">
                                        <Form.Label>{t('Website Name')}</Form.Label>
                                        <Form.Control
                                            name="website_name"
                                            required
                                            type="text"
                                            placeholder={t('Example: her website')}
                                        />
                                    </Form.Group>
                                </Row>

                                <Form.Group className="mb-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <Form.Label>{t('Website Description?')}</Form.Label>
                                        <small className="text-muted">{textAreaValue.length}/500</small>
                                    </div>
                                    <Form.Control
                                        name="website_description"
                                        required
                                        as="textarea"
                                        type="text"
                                        placeholder={t('xample: Everything related to women')}
                                        rows="9"
                                        maxLength={500}
                                        value={textAreaValue}
                                        onChange={(e) => {
                                            setTextAreaValue(e.currentTarget.value)
                                        }}
                                    />
                                </Form.Group>
                                {(pathName === 'answers-for-faqs') && (
                                    <Form.Group className="mb-3">
                                        <Form.Label>{t('What is the question you are generating answers for?')}</Form.Label>
                                        <Form.Control
                                            name="answers_for"
                                            required
                                            type="text"
                                            placeholder={t('Your question here')}
                                        />
                                    </Form.Group>
                                )}
                            </>
                        )}


                        {(['user-testimonials-reviews']).includes(pathName) && (
                            <>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('Tone of voice')}</Form.Label>
                                    <Form.Select name="tone_of_voice">
                                        <option value="professional">{t('Professional')}</option>
                                        <option value="childish">{t('Childish')}</option>
                                        <option value="luxurious">{t('Luxurious')}</option>
                                        <option value="friendly">{t('Friendly')}</option>
                                        <option value="confident">{t('Confident')}</option>
                                        <option value="exciting">{t('Exciting')}</option>
                                    </Form.Select>
                                </Form.Group>
                                {/* )} */}
                                <Row className="mb-3">
                                    {(pathName !== 'user-testimonials-reviews') && (
                                        <Form.Group as={Col}>
                                            <Form.Label>{t('client')}</Form.Label>
                                            <Form.Control
                                                name="audience"
                                                required
                                                type="text"
                                                placeholder={t('Freelancers, Designers,...')}
                                            />
                                        </Form.Group>
                                    )}
                                    <Form.Group as={Col} controlId="validationCustom02">
                                        <Form.Label>{t('[Product] Name')}</Form.Label>
                                        <Form.Control
                                            name="product_name"
                                            required
                                            type="text"
                                            placeholder={t('Netflix, Spotify, Uber...')}
                                        />
                                    </Form.Group>
                                </Row>

                                <Form.Group className="mb-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <Form.Label>{t('Product Description?')}</Form.Label>
                                        <small className="text-muted">{textAreaValue.length}/500</small>
                                    </div>
                                    <Form.Control
                                        name="product_description"
                                        required
                                        as="textarea"
                                        type="text"
                                        placeholder={t('Explain here to the AI what your product (or service) is about. Rewrite to get different results.')}
                                        rows="9"
                                        maxLength={500}
                                        value={textAreaValue}
                                        onChange={(e) => {
                                            setTextAreaValue(e.currentTarget.value)
                                        }}
                                    />
                                </Form.Group>

                            </>
                        )}

                        {(['call-to-action-phrases']).includes(pathName) && (
                            <>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('Tone of voice')}</Form.Label>
                                    <Form.Select name="tone_of_voice">
                                        <option value="professional">{t('Professional')}</option>
                                        <option value="childish">{t('Childish')}</option>
                                        <option value="luxurious">{t('Luxurious')}</option>
                                        <option value="friendly">{t('Friendly')}</option>
                                        <option value="confident">{t('Confident')}</option>
                                        <option value="exciting">{t('Exciting')}</option>
                                    </Form.Select>
                                </Form.Group>
                                {/* )} */}
                                <Row className="mb-3">
                                    {(pathName !== 'user-testimonials-reviews') && (
                                        <Form.Group as={Col}>
                                            <Form.Label>{t('the target audience')}</Form.Label>
                                            <Form.Control
                                                name="audience"
                                                required
                                                type="text"
                                                placeholder={t('Example: housewives')}
                                            />
                                        </Form.Group>
                                    )}
                                    <Form.Group as={Col} controlId="validationCustom02">
                                        <Form.Label>{t('[Product] Name')}</Form.Label>
                                        <Form.Control
                                            name="product_name"
                                            required
                                            type="text"
                                            placeholder={t('Example: air freshener')}
                                        />
                                    </Form.Group>
                                </Row>

                                <Form.Group className="mb-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <Form.Label>{t('What is the procedure or occasion?')}</Form.Label>
                                        <small className="text-muted">{textAreaValue.length}/500</small>
                                    </div>
                                    <Form.Control
                                        name="product_description"
                                        required
                                        as="textarea"
                                        type="text"
                                        placeholder={t('Example: discount on the occasion of National Day')}
                                        rows="9"
                                        maxLength={500}
                                        value={textAreaValue}
                                        onChange={(e) => {
                                            setTextAreaValue(e.currentTarget.value)
                                        }}
                                    />
                                </Form.Group>

                            </>
                        )}

                        {(['our-story']).includes(pathName) && (
                            <>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('Tone of voice')}</Form.Label>
                                    <Form.Select name="tone_of_voice">
                                        <option value="professional">{t('Professional')}</option>
                                        <option value="childish">{t('Childish')}</option>
                                        <option value="luxurious">{t('Luxurious')}</option>
                                        <option value="friendly">{t('Friendly')}</option>
                                        <option value="confident">{t('Confident')}</option>
                                        <option value="exciting">{t('Exciting')}</option>
                                    </Form.Select>
                                </Form.Group>
                                {/* )} */}
                                <Row className="mb-3">
                                    {(pathName !== 'user-testimonials-reviews') && (
                                        <Form.Group as={Col}>
                                            <Form.Label>{t('the target audience')}</Form.Label>
                                            <Form.Control
                                                name="audience"
                                                required
                                                type="text"
                                                placeholder={t('Example: reading newspapers')}
                                            />
                                        </Form.Group>
                                    )}
                                    <Form.Group as={Col} controlId="validationCustom02">
                                        <Form.Label>{t('Company Name')}</Form.Label>
                                        <Form.Control
                                            name="product_name"
                                            required
                                            type="text"
                                            placeholder={t('Example: Asharq Al-Awsat newspaper')}
                                        />
                                    </Form.Group>
                                </Row>

                                <Form.Group className="mb-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <Form.Label>{t('A simple overview of the company or institution')}</Form.Label>
                                        <small className="text-muted">{textAreaValue.length}/500</small>
                                    </div>
                                    <Form.Control
                                        name="product_description"
                                        required
                                        as="textarea"
                                        type="text"
                                        placeholder={t('Example: Asharq Al-Awsat newspaper, an international Arab newspaper. Paper and electronic, its content varies to cover regional political news and social issues. It was established in 1978 AD.')}
                                        rows="9"
                                        maxLength={500}
                                        value={textAreaValue}
                                        onChange={(e) => {
                                            setTextAreaValue(e.currentTarget.value)
                                        }}
                                    />
                                </Form.Group>

                            </>
                        )}

                        {(['how-your-product-works']).includes(pathName) && (
                            <>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('Tone of voice')}</Form.Label>
                                    <Form.Select name="tone_of_voice">
                                        <option value="professional">{t('Professional')}</option>
                                        <option value="childish">{t('Childish')}</option>
                                        <option value="luxurious">{t('Luxurious')}</option>
                                        <option value="friendly">{t('Friendly')}</option>
                                        <option value="confident">{t('Confident')}</option>
                                        <option value="exciting">{t('Exciting')}</option>
                                    </Form.Select>
                                </Form.Group>
                                {/* )} */}
                                <Row className="mb-3">
                                    {(pathName !== 'user-testimonials-reviews') && (
                                        <Form.Group as={Col}>
                                            <Form.Label>{t('the target auience')}</Form.Label>
                                            <Form.Control
                                                name="audience"
                                                required
                                                type="text"
                                                placeholder={t('Example: content creators')}
                                            />
                                        </Form.Group>
                                    )}
                                    <Form.Group as={Col} controlId="validationCustom02">
                                        <Form.Label>{t('[Product] Name')}</Form.Label>
                                        <Form.Control
                                            name="product_name"
                                            required
                                            type="text"
                                            placeholder={t('Example: Belinda Assistant website')}
                                        />
                                    </Form.Group>
                                </Row>

                                <Form.Group className="mb-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <Form.Label>{t('Product Description?')}</Form.Label>
                                        <small className="text-muted">{textAreaValue.length}/500</small>
                                    </div>
                                    <Form.Control
                                        name="product_description"
                                        required
                                        as="textarea"
                                        type="text"
                                        placeholder={t('Example: Belinda Assistant, a website that relies on artificial intelligence to build distinctive content')}
                                        rows="9"
                                        maxLength={500}
                                        value={textAreaValue}
                                        onChange={(e) => {
                                            setTextAreaValue(e.currentTarget.value)
                                        }}
                                    />
                                </Form.Group>

                            </>
                        )}




                        {(['facebook-ad-content', 'facebook-ad-headline-writing', 'google-ads-headline-creation', 'google-ads-description-writing']).includes(pathName) && (
                            <>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('Tone of voice')}</Form.Label>
                                    <Form.Select name="tone_of_voice">
                                        <option value="professional">{t('Professional')}</option>
                                        <option value="childish">{t('Childish')}</option>
                                        <option value="luxurious">{t('Luxurious')}</option>
                                        <option value="friendly">{t('Friendly')}</option>
                                        <option value="confident">{t('Confident')}</option>
                                        <option value="exciting">{t('Exciting')}</option>
                                    </Form.Select>
                                </Form.Group>
                                {/* )} */}
                                <Row className="mb-3">
                                    {(pathName !== 'user-testimonials-reviews') && (
                                        <Form.Group as={Col}>
                                            <Form.Label>{t('The target audience')}</Form.Label>
                                            <Form.Control
                                                name="audience"
                                                required
                                                type="text"
                                                placeholder={t('Example: Those interested in real estate')}
                                            />
                                        </Form.Group>
                                    )}
                                    <Form.Group as={Col} controlId="validationCustom02">
                                        <Form.Label>{t('[Product] Name')}</Form.Label>
                                        <Form.Control
                                            name="product_name"
                                            required
                                            type="text"
                                            placeholder={t('Diamond Project')}
                                        />
                                    </Form.Group>
                                </Row>

                                <Form.Group className="mb-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <Form.Label>{t('What is your ad?')}</Form.Label>
                                        <small className="text-muted">{textAreaValue.length}/500</small>
                                    </div>
                                    <Form.Control
                                        name="product_description"
                                        required
                                        as="textarea"
                                        type="text"
                                        placeholder={t('Example: The sale of residential units, villas, apartments, and townhouses, has begun')}
                                        rows="9"
                                        maxLength={500}
                                        value={textAreaValue}
                                        onChange={(e) => {
                                            setTextAreaValue(e.currentTarget.value)
                                        }}
                                    />
                                </Form.Group>

                            </>
                        )}




                        {(['frequently-asked-questions', 'answers-for-faqs']).includes(pathName) && (
                            <>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('Tone of voice')}</Form.Label>
                                    <Form.Select name="tone_of_voice">
                                        <option value="professional">{t('Professional')}</option>
                                        <option value="childish">{t('Childish')}</option>
                                        <option value="luxurious">{t('Luxurious')}</option>
                                        <option value="friendly">{t('Friendly')}</option>
                                        <option value="confident">{t('Confident')}</option>
                                        <option value="exciting">{t('Exciting')}</option>
                                    </Form.Select>
                                </Form.Group>
                                {/* )} */}
                                <Row className="mb-3">
                                    {(pathName !== 'user-testimonials-reviews') && (
                                        <Form.Group as={Col}>
                                            <Form.Label>{t('the target audience')}</Form.Label>
                                            <Form.Control
                                                name="audience"
                                                required
                                                type="text"
                                                placeholder={t('Example: Those interested in classic cars')}
                                            />
                                        </Form.Group>
                                    )}
                                    <Form.Group as={Col} controlId="validationCustom02">
                                        <Form.Label>{t('[Product] Name')}</Form.Label>
                                        <Form.Control
                                            name="product_name"
                                            required
                                            type="text"
                                            placeholder={t('Example: A site for classic car auctions')}
                                        />
                                    </Form.Group>
                                </Row>

                                <Form.Group className="mb-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <Form.Label>{t('Product Description?')}</Form.Label>
                                        <small className="text-muted">{textAreaValue.length}/500</small>
                                    </div>
                                    <Form.Control
                                        name="product_description"
                                        required
                                        as="textarea"
                                        type="text"
                                        placeholder={t('Example site for live classic car auctions')}
                                        rows="9"
                                        maxLength={500}
                                        value={textAreaValue}
                                        onChange={(e) => {
                                            setTextAreaValue(e.currentTarget.value)
                                        }}
                                    />
                                </Form.Group>
                                {(pathName === 'answers-for-faqs') && (
                                    <Form.Group className="mb-3">
                                        <Form.Label>{t('What is the question you are generating answers for?')}</Form.Label>
                                        <Form.Control
                                            name="answers_for"
                                            required
                                            type="text"
                                            placeholder={t('Your question here')}
                                        />
                                    </Form.Group>
                                )}
                            </>
                        )}

                        {(['pain-stimulate-solution']).includes(pathName) && (
                            <>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('Tone of voice')}</Form.Label>
                                    <Form.Select name="tone_of_voice">
                                        <option value="professional">{t('Professional')}</option>
                                        <option value="childish">{t('Childish')}</option>
                                        <option value="luxurious">{t('Luxurious')}</option>
                                        <option value="friendly">{t('Friendly')}</option>
                                        <option value="confident">{t('Confident')}</option>
                                        <option value="exciting">{t('Exciting')}</option>
                                    </Form.Select>
                                </Form.Group>
                                {/* )} */}
                                <Row className="mb-3">
                                    {(pathName !== 'user-testimonials-reviews') && (
                                        <Form.Group as={Col}>
                                            <Form.Label>{t('the target audience')}</Form.Label>
                                            <Form.Control
                                                name="audience"
                                                required
                                                type="text"
                                                placeholder={t('Example: Those interested in real estate')}
                                            />
                                        </Form.Group>
                                    )}
                                    <Form.Group as={Col} controlId="validationCustom02">
                                        <Form.Label>{t('[Product] Name')}</Form.Label>
                                        <Form.Control
                                            name="product_name"
                                            required
                                            type="text"
                                            placeholder={t('Example: Residential Prosperity Plan')}
                                        />
                                    </Form.Group>
                                </Row>

                                <Form.Group className="mb-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <Form.Label>{t('Product Description?')}</Form.Label>
                                        <small className="text-muted">{textAreaValue.length}/500</small>
                                    </div>
                                    <Form.Control
                                        name="product_description"
                                        required
                                        as="textarea"
                                        type="text"
                                        placeholder={t('Example: The sale began with the Al Izdihar residential scheme and the vacancy is immediate')}
                                        rows="9"
                                        maxLength={500}
                                        value={textAreaValue}
                                        onChange={(e) => {
                                            setTextAreaValue(e.currentTarget.value)
                                        }}
                                    />
                                </Form.Group>
                            </>
                        )}

                        {(['create-product-descriptions']).includes(pathName) && (
                            <>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('Tone of voice')}</Form.Label>
                                    <Form.Select name="tone_of_voice">
                                        <option value="professional">{t('Professional')}</option>
                                        <option value="childish">{t('Childish')}</option>
                                        <option value="luxurious">{t('Luxurious')}</option>
                                        <option value="friendly">{t('Friendly')}</option>
                                        <option value="confident">{t('Confident')}</option>
                                        <option value="exciting">{t('Exciting')}</option>
                                    </Form.Select>
                                </Form.Group>
                                {/* )} */}
                                <Row className="mb-3">
                                    {(pathName !== 'user-testimonials-reviews') && (
                                        <Form.Group as={Col}>
                                            <Form.Label>{t('Directed to')}</Form.Label>
                                            <Form.Control
                                                name="audience"
                                                required
                                                type="text"
                                                placeholder={t('Example: Recent university graduates?')}
                                            />
                                        </Form.Group>
                                    )}
                                    <Form.Group as={Col} controlId="validationCustom02">
                                        <Form.Label>{t('[Product] Name')}</Form.Label>
                                        <Form.Control
                                            name="product_name"
                                            required
                                            type="text"
                                            placeholder={t('Example: Diploma in English Language')}
                                        />
                                    </Form.Group>
                                </Row>

                                <Form.Group className="mb-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <Form.Label>{t('Product Description?')}</Form.Label>
                                        <small className="text-muted">{textAreaValue.length}/500</small>
                                    </div>
                                    <Form.Control
                                        name="product_description"
                                        required
                                        as="textarea"
                                        type="text"
                                        placeholder={t('Example: Intensive English Diploma for 6 months')}
                                        rows="9"
                                        maxLength={500}
                                        value={textAreaValue}
                                        onChange={(e) => {
                                            setTextAreaValue(e.currentTarget.value)
                                        }}
                                    />
                                </Form.Group>
                                {(pathName === 'answers-for-faqs') && (
                                    <Form.Group className="mb-3">
                                        <Form.Label>{t('What is the question you are generating answers for?')}</Form.Label>
                                        <Form.Control
                                            name="answers_for"
                                            required
                                            type="text"
                                            placeholder={t('Your question here')}
                                        />
                                    </Form.Group>
                                )}
                            </>
                        )}

                        {(['craft-unique-value-proposition']).includes(pathName) && (
                            <>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('Tone of voice')}</Form.Label>
                                    <Form.Select name="tone_of_voice">
                                        <option value="professional">{t('Professional')}</option>
                                        <option value="childish">{t('Childish')}</option>
                                        <option value="luxurious">{t('Luxurious')}</option>
                                        <option value="friendly">{t('Friendly')}</option>
                                        <option value="confident">{t('Confident')}</option>
                                        <option value="exciting">{t('Exciting')}</option>
                                    </Form.Select>
                                </Form.Group>
                                {/* )} */}
                                <Row className="mb-3">
                                    {(pathName !== 'user-testimonials-reviews') && (
                                        <Form.Group as={Col}>
                                            <Form.Label>{t('The target audience')}</Form.Label>
                                            <Form.Control
                                                name="audience"
                                                required
                                                type="text"
                                                placeholder={t('Freelancers, Designers,...')}
                                            />
                                        </Form.Group>
                                    )}
                                    <Form.Group as={Col} controlId="validationCustom02">
                                        <Form.Label>{t('[Product] Name')}</Form.Label>
                                        <Form.Control
                                            name="product_name"
                                            required
                                            type="text"
                                            placeholder={t('Netflix, Spotify, Uber...')}
                                        />
                                    </Form.Group>
                                </Row>

                                <Form.Group className="mb-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <Form.Label>{t('Product Description?')}</Form.Label>
                                        <small className="text-muted">{textAreaValue.length}/500</small>
                                    </div>
                                    <Form.Control
                                        name="product_description"
                                        required
                                        as="textarea"
                                        type="text"
                                        placeholder={t('Adviser application for lawyers who provide legal advice in return for money')}
                                        rows="9"
                                        maxLength={500}
                                        value={textAreaValue}
                                        onChange={(e) => {
                                            setTextAreaValue(e.currentTarget.value)
                                        }}
                                    />
                                </Form.Group>
                                {(pathName === 'answers-for-faqs') && (
                                    <Form.Group className="mb-3">
                                        <Form.Label>{t('What is the question you are generating answers for?')}</Form.Label>
                                        <Form.Control
                                            name="answers_for"
                                            required
                                            type="text"
                                            placeholder={t('Your question here')}
                                        />
                                    </Form.Group>
                                )}
                            </>
                        )}


                        {(['turn-features-into-benefits']).includes(pathName) && (
                            <>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('Tone of voice')}</Form.Label>
                                    <Form.Select name="tone_of_voice">
                                        <option value="professional">{t('Professional')}</option>
                                        <option value="childish">{t('Childish')}</option>
                                        <option value="luxurious">{t('Luxurious')}</option>
                                        <option value="friendly">{t('Friendly')}</option>
                                        <option value="confident">{t('Confident')}</option>
                                        <option value="exciting">{t('Exciting')}</option>
                                    </Form.Select>
                                </Form.Group>
                                {/* )} */}
                                <Row className="mb-3">
                                    <Form.Group as={Col}>
                                        <Form.Label>{t('The target audience')}</Form.Label>
                                        <Form.Control
                                            name="audience"
                                            required
                                            type="text"
                                            placeholder={t('Freelancers, Designers,...')}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="validationCustom02">
                                        <Form.Label>{t('[Product] Name')}</Form.Label>
                                        <Form.Control
                                            name="product_name"
                                            required
                                            type="text"
                                            placeholder={t('Netflix, Spotify, Uber...')}
                                        />
                                    </Form.Group>
                                </Row>

                                <Form.Group className="mb-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <Form.Label>{t('the topic')}</Form.Label>
                                        <small className="text-muted">{textAreaValue.length}/500</small>
                                    </div>
                                    <Form.Control
                                        name="product_description"
                                        required
                                        as="textarea"
                                        type="text"
                                        placeholder={t('Adviser application for lawyers who provide legal advice in return for money')}
                                        rows="9"
                                        maxLength={500}
                                        value={textAreaValue}
                                        onChange={(e) => {
                                            setTextAreaValue(e.currentTarget.value)
                                        }}
                                    />
                                </Form.Group>

                            </>
                        )}


                        {(['write-email-subject-lines']).includes(pathName) && (
                            <>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('Tone of voice')}</Form.Label>
                                    <Form.Select name="tone_of_voice">
                                        <option value="professional">{t('Professional')}</option>
                                        <option value="childish">{t('Childish')}</option>
                                        <option value="luxurious">{t('Luxurious')}</option>
                                        <option value="friendly">{t('Friendly')}</option>
                                        <option value="confident">{t('Confident')}</option>
                                        <option value="exciting">{t('Exciting')}</option>
                                    </Form.Select>
                                </Form.Group>
                                {/* )} */}
                                <Row className="mb-3">
                                    <Form.Group as={Col}>
                                        <Form.Label>{t('To')}</Form.Label>
                                        <Form.Control
                                            name="audience"
                                            required
                                            type="text"
                                            placeholder={t('Example: silver package customers')}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="validationCustom02">
                                        <Form.Label>{t('[Product] Name')}</Form.Label>
                                        <Form.Control
                                            name="product_name"
                                            required
                                            type="text"
                                            placeholder={t('Example: National Day presentation')}
                                        />
                                    </Form.Group>
                                </Row>

                                <Form.Group className="mb-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <Form.Label>{t('the topic')}</Form.Label>
                                        <small className="text-muted">{textAreaValue.length}/500</small>
                                    </div>
                                    <Form.Control
                                        name="product_description"
                                        required
                                        as="textarea"
                                        type="text"
                                        placeholder={t('Opening of Al-Rayyan Women`s Branch in Riyadh')}
                                        rows="9"
                                        maxLength={500}
                                        value={textAreaValue}
                                        onChange={(e) => {
                                            setTextAreaValue(e.currentTarget.value)
                                        }}
                                    />
                                </Form.Group>

                            </>
                        )}


                        {(['generate-confirmation-emails']).includes(pathName) && (
                            <>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('Tone of voice')}</Form.Label>
                                    <Form.Select name="tone_of_voice">
                                        <option value="professional">{t('Professional')}</option>
                                        <option value="childish">{t('Childish')}</option>
                                        <option value="luxurious">{t('Luxurious')}</option>
                                        <option value="friendly">{t('Friendly')}</option>
                                        <option value="confident">{t('Confident')}</option>
                                        <option value="exciting">{t('Exciting')}</option>
                                    </Form.Select>
                                </Form.Group>
                                {/* )} */}
                                <Row className="mb-3">
                                    <Form.Group as={Col}>
                                        <Form.Label>{t('Directed to')}</Form.Label>
                                        <Form.Control
                                            name="audience"
                                            required
                                            type="text"
                                            placeholder={t('Example: Those registered for the course')}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="validationCustom02">
                                        <Form.Label>{t('Name of the event or event')}</Form.Label>
                                        <Form.Control
                                            name="product_name"
                                            required
                                            type="text"
                                            placeholder={t('Example: Content writing course')}
                                        />
                                    </Form.Group>
                                </Row>
                                {(pathName === 'generate-confirmation-emails') && (
                                    <Form.Group className="mb-3">
                                        <Form.Label>{t('Confirmation of')}</Form.Label>
                                        <Form.Control
                                            name="confirmation_of"
                                            required
                                            type="text"
                                            placeholder={t('Example: holding the session')}
                                        />
                                    </Form.Group>
                                )}
                                <Form.Group className="mb-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <Form.Label>{t('An overview of the event or event')}</Form.Label>
                                        <small className="text-muted">{textAreaValue.length}/500</small>
                                    </div>
                                    <Form.Control
                                        name="product_description"
                                        required
                                        as="textarea"
                                        type="text"
                                        placeholder={t('Example: Regarding the date of the training course')}
                                        rows="9"
                                        maxLength={500}
                                        value={textAreaValue}
                                        onChange={(e) => {
                                            setTextAreaValue(e.currentTarget.value)
                                        }}
                                    />
                                </Form.Group>
                                {(pathName === 'answers-for-faqs') && (
                                    <Form.Group className="mb-3">
                                        <Form.Label>{t('What is the question you are generating answers for?')}</Form.Label>
                                        <Form.Control
                                            name="answers_for"
                                            required
                                            type="text"
                                            placeholder={t('Your question here')}
                                        />
                                    </Form.Group>
                                )}
                            </>
                        )}

                        {(['write-welcome-emails']).includes(pathName) && (
                            <>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('Tone of voice')}</Form.Label>
                                    <Form.Select name="tone_of_voice">
                                        <option value="professional">{t('Professional')}</option>
                                        <option value="childish">{t('Childish')}</option>
                                        <option value="luxurious">{t('Luxurious')}</option>
                                        <option value="friendly">{t('Friendly')}</option>
                                        <option value="confident">{t('Confident')}</option>
                                        <option value="exciting">{t('Exciting')}</option>
                                    </Form.Select>
                                </Form.Group>
                                {/* )} */}
                                <Row className="mb-3">
                                    {(pathName !== 'user-testimonials-reviews') && (
                                        <Form.Group as={Col}>
                                            <Form.Label>{t('Directed to')}</Form.Label>
                                            <Form.Control
                                                name="audience"
                                                required
                                                type="text"
                                                placeholder={t('Customers of mobile and internet packages')}
                                            />
                                        </Form.Group>
                                    )}
                                    <Form.Group as={Col} controlId="validationCustom02">
                                        <Form.Label>{t('[Product] Name')}</Form.Label>
                                        <Form.Control
                                            name="product_name"
                                            required
                                            type="text"
                                            placeholder={t('home package')}
                                        />
                                    </Form.Group>
                                </Row>

                                <Form.Group className="mb-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <Form.Label>{t('Product Description?')}</Form.Label>
                                        <small className="text-muted">{textAreaValue.length}/500</small>
                                    </div>
                                    <Form.Control
                                        name="product_description"
                                        required
                                        as="textarea"
                                        type="text"
                                        placeholder={t('The best 5G coverage the speed you need')}
                                        rows="9"
                                        maxLength={500}
                                        value={textAreaValue}
                                        onChange={(e) => {
                                            setTextAreaValue(e.currentTarget.value)
                                        }}
                                    />
                                </Form.Group>

                            </>
                        )}



                        {(['cancellation-email']).includes(pathName) && (
                            <>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('Tone of voice')}</Form.Label>
                                    <Form.Select name="tone_of_voice">
                                        <option value="professional">{t('Professional')}</option>
                                        <option value="childish">{t('Childish')}</option>
                                        <option value="luxurious">{t('Luxurious')}</option>
                                        <option value="friendly">{t('Friendly')}</option>
                                        <option value="confident">{t('Confident')}</option>
                                        <option value="exciting">{t('Exciting')}</option>
                                    </Form.Select>
                                </Form.Group>
                                {/* )} */}
                                <Row className="mb-3">
                                    <Form.Group as={Col}>
                                        <Form.Label>{t('client')}</Form.Label>
                                        <Form.Control
                                            name="audience"
                                            required
                                            type="text"
                                            placeholder={t('ahmed')}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="validationCustom02">
                                        <Form.Label>{t('[Product] Name')}</Form.Label>
                                        <Form.Control
                                            name="product_name"
                                            required
                                            type="text"
                                            placeholder={t('The golden package')}
                                        />
                                    </Form.Group>
                                </Row>
                                {(pathName === 'generate-confirmation-emails') && (
                                    <Form.Group className="mb-3">
                                        <Form.Label>{t('Confirmation of')}</Form.Label>
                                        <Form.Control
                                            name="confirmation_of"
                                            required
                                            type="text"
                                            placeholder={t('Sale, Sign-up,...')}
                                        />
                                    </Form.Group>
                                )}
                                <Form.Group className="mb-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <Form.Label>{t('Reason for cancellation')}</Form.Label>
                                        <small className="text-muted">{textAreaValue.length}/500</small>
                                    </div>
                                    <Form.Control
                                        name="product_description"
                                        required
                                        as="textarea"
                                        type="text"
                                        placeholder={t('Non-compliance with payments')}
                                        rows="9"
                                        maxLength={500}
                                        value={textAreaValue}
                                        onChange={(e) => {
                                            setTextAreaValue(e.currentTarget.value)
                                        }}
                                    />
                                </Form.Group>
                                {(pathName === 'answers-for-faqs') && (
                                    <Form.Group className="mb-3">
                                        <Form.Label>{t('What is the question you are generating answers for?')}</Form.Label>
                                        <Form.Control
                                            name="answers_for"
                                            required
                                            type="text"
                                            placeholder={t('Your question here')}
                                        />
                                    </Form.Group>
                                )}
                            </>
                        )}





                        {(['define-company-mission', 'write-vision-statement']).includes(pathName) && (
                            <>
                                <Row className="mb-3">
                                    {(pathName !== 'user-testimonials-reviews') && (
                                        <Form.Group as={Col}>
                                            <Form.Label>{t('Company Name')}</Form.Label>
                                            <Form.Control
                                                name="company_name"
                                                required
                                                type="text"
                                                placeholder={t('Almaraei')}
                                            />
                                        </Form.Group>
                                    )}
                                </Row>
                                <Form.Group className="mb-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <Form.Label>{t('Company Description?')}</Form.Label>
                                        <small className="text-muted">{textAreaValue.length}/500</small>
                                    </div>
                                    <Form.Control
                                        name="company_description"
                                        required
                                        as="textarea"
                                        type="text"
                                        placeholder={t('Almarai is a Saudi joint stock company working in the fields of agriculture, dairy products, and food distribution, covering Saudi Arabia and the Arab Gulf countries')}
                                        rows="9"
                                        maxLength={500}
                                        value={textAreaValue}
                                        onChange={(e) => {
                                            setTextAreaValue(e.currentTarget.value)
                                        }}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>{t('Tone of voice')}</Form.Label>
                                    <Form.Select name="tone_of_voice">
                                        <option value="professional">{t('Professional')}</option>
                                        <option value="childish">{t('Childish')}</option>
                                        <option value="luxurious">{t('Luxurious')}</option>
                                        <option value="friendly">{t('Friendly')}</option>
                                        <option value="confident">{t('Confident')}</option>
                                        <option value="exciting">{t('Exciting')}</option>
                                    </Form.Select>
                                </Form.Group>
                            </>
                        )}

                        {(['write-press-releases']).includes(pathName) && (
                            <>
                                <Row className="mb-3">
                                    {(pathName !== 'user-testimonials-reviews') && (
                                        <Form.Group as={Col}>
                                            <Form.Label>{t('Company Name')}</Form.Label>
                                            <Form.Control
                                                name="company_name"
                                                required
                                                type="text"
                                                placeholder={t('Saudi arabia Airlines')}
                                            />
                                        </Form.Group>
                                    )}
                                </Row>
                                <Form.Group className="mb-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <Form.Label>{t('About the press release')}</Form.Label>
                                        <small className="text-muted">{textAreaValue.length}/500</small>
                                    </div>
                                    <Form.Control
                                        name="company_description"
                                        required
                                        as="textarea"
                                        type="text"
                                        placeholder={t('Launching the new identity of Saudi Airlines')}
                                        rows="9"
                                        maxLength={500}
                                        value={textAreaValue}
                                        onChange={(e) => {
                                            setTextAreaValue(e.currentTarget.value)
                                        }}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>{t('Tone of voice')}</Form.Label>
                                    <Form.Select name="tone_of_voice">
                                        <option value="professional">{t('Professional')}</option>
                                        <option value="childish">{t('Childish')}</option>
                                        <option value="luxurious">{t('Luxurious')}</option>
                                        <option value="friendly">{t('Friendly')}</option>
                                        <option value="confident">{t('Confident')}</option>
                                        <option value="exciting">{t('Exciting')}</option>
                                    </Form.Select>
                                </Form.Group>
                            </>
                        )}


                        {(['business-social-media-post']).includes(pathName) && (
                            <>
                                <Row className="mb-3">
                                    {(pathName !== 'user-testimonials-reviews') && (
                                        <Form.Group as={Col}>
                                            <Form.Label>{t('Company Name')}</Form.Label>
                                            <Form.Control
                                                name="company_name"
                                                required
                                                type="text"
                                                placeholder={t('Amazon')}
                                            />
                                        </Form.Group>
                                    )}
                                </Row>
                                <Form.Group className="mb-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <Form.Label>{t('Company Description?')}</Form.Label>
                                        <small className="text-muted">{textAreaValue.length}/500</small>
                                    </div>
                                    <Form.Control
                                        name="company_description"
                                        required
                                        as="textarea"
                                        type="text"
                                        placeholder={t('Example: e-commerce and cloud computing website')}
                                        rows="9"
                                        maxLength={500}
                                        value={textAreaValue}
                                        onChange={(e) => {
                                            setTextAreaValue(e.currentTarget.value)
                                        }}
                                    />
                                </Form.Group>
                                <Row className="mb-3">
                                    <Form.Group as={Col}>
                                        <Form.Label>{t('What is this post about?')}</Form.Label>
                                        <Form.Control
                                            name="post_content"
                                            required
                                            type="text"
                                            placeholder={t('Example: He talks about launching the payment gateway service for electronic stores in the Kingdom of Saudi Arabia')}
                                        />
                                    </Form.Group>
                                </Row>

                                <Form.Group className="mb-3">
                                    <Form.Label>{t('Tone of voice')}</Form.Label>
                                    <Form.Select name="tone_of_voice">
                                        <option value="professional">{t('Professional')}</option>
                                        <option value="childish">{t('Childish')}</option>
                                        <option value="luxurious">{t('Luxurious')}</option>
                                        <option value="friendly">{t('Friendly')}</option>
                                        <option value="confident">{t('Confident')}</option>
                                        <option value="exciting">{t('Exciting')}</option>
                                    </Form.Select>
                                </Form.Group>
                            </>
                        )}
                        {(['instagram-hashtag-recommendations']).includes(pathName) && (
                            <Row className="mb-3">
                                <Form.Group as={Col}>
                                    <Form.Label>{t('Enter a keyword')}</Form.Label>
                                    <Form.Control
                                        name="post_content"
                                        required
                                        as="textarea"
                                        type="text"
                                        placeholder={t('e.g yoga')}
                                        rows="9"
                                        maxLength={500}
                                        value={textAreaValue}
                                        onChange={(e) => {
                                            setTextAreaValue(e.currentTarget.value)
                                        }}
                                    />
                                </Form.Group>
                            </Row>
                        )}
                        {(['personal-social-media-update']).includes(pathName) && (
                            <Row className="mb-3">
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('Tone of voice')}</Form.Label>
                                    <Form.Select name="tone_of_voice">
                                        <option value="professional">{t('Professional')}</option>
                                        <option value="childish">{t('Childish')}</option>
                                        <option value="luxurious">{t('Luxurious')}</option>
                                        <option value="friendly">{t('Friendly')}</option>
                                        <option value="confident">{t('Confident')}</option>
                                        <option value="exciting">{t('Exciting')}</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Label>{t('What is this post about?')}</Form.Label>
                                    <Form.Control
                                        name="post_content"
                                        required
                                        as="textarea"
                                        type="text"
                                        placeholder={t('Heading to the beach on weekend')}
                                        rows="9"
                                        maxLength={500}
                                        value={textAreaValue}
                                        onChange={(e) => {
                                            setTextAreaValue(e.currentTarget.value)
                                        }}
                                    />
                                </Form.Group>
                            </Row>
                        )}
                        {(['instagram-post-captions']).includes(pathName) && (
                            <Row className="mb-3">
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('Tone of voice')}</Form.Label>
                                    <Form.Select name="tone_of_voice">
                                        <option value="professional">{t('Professional')}</option>
                                        <option value="childish">{t('Childish')}</option>
                                        <option value="luxurious">{t('Luxurious')}</option>
                                        <option value="friendly">{t('Friendly')}</option>
                                        <option value="confident">{t('Confident')}</option>
                                        <option value="exciting">{t('Exciting')}</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Label>{t('What is this post about?')}</Form.Label>
                                    <Form.Control
                                        name="post_content"
                                        required
                                        as="textarea"
                                        type="text"
                                        placeholder={t('Example: My graduation ceremony today')}
                                        rows="9"
                                        maxLength={500}
                                        value={textAreaValue}
                                        onChange={(e) => {
                                            setTextAreaValue(e.currentTarget.value)
                                        }}
                                    />
                                </Form.Group>
                            </Row>
                        )}
                        {['youtube-video-titles', 'write-video-intros', 'organize-video-scripts'].includes(pathName) && (
                            <>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('Tone of voice')}</Form.Label>
                                    <Form.Select name="tone_of_voice">
                                        <option value="professional">{t('Professional')}</option>
                                        <option value="childish">{t('Childish')}</option>
                                        <option value="luxurious">{t('Luxurious')}</option>
                                        <option value="friendly">{t('Friendly')}</option>
                                        <option value="confident">{t('Confident')}</option>
                                        <option value="exciting">{t('Exciting')}</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <Form.Label>{t('What is the video that you want to create about?')}</Form.Label>
                                        <small className="text-muted">{textAreaValue.length}/200</small>
                                    </div>
                                    <Form.Control
                                        name="video_description"
                                        required
                                        as="textarea"
                                        type="text"
                                        placeholder={t('How to write your smart goal')}
                                        rows="9"
                                        maxLength={500}
                                        value={textAreaValue}
                                        onChange={(e) => {
                                            setTextAreaValue(e.currentTarget.value)
                                        }}
                                    />
                                </Form.Group>
                            </>
                        )}
                        {['generate-video-content'].includes(pathName) && (
                            <>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('Tone of voice')}</Form.Label>
                                    <Form.Select name="tone_of_voice">
                                        <option value="professional">{t('Professional')}</option>
                                        <option value="childish">{t('Childish')}</option>
                                        <option value="luxurious">{t('Luxurious')}</option>
                                        <option value="friendly">{t('Friendly')}</option>
                                        <option value="confident">{t('Confident')}</option>
                                        <option value="exciting">{t('Exciting')}</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('Section title')}</Form.Label>
                                    <Form.Control
                                        name="section_title"
                                        required
                                        type="text"
                                        placeholder={t('e.g. Benefits of using an AI Copywriter')}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <Form.Label>{t('What is this paragraph talking about')}</Form.Label>
                                        <small className="text-muted">{textAreaValue.length}/200</small>
                                    </div>
                                    <Form.Control
                                        name="video_description"
                                        required
                                        as="textarea"
                                        type="text"
                                        placeholder={t('How to write your smart goal')}
                                        rows="9"
                                        maxLength={500}
                                        value={textAreaValue}
                                        onChange={(e) => {
                                            setTextAreaValue(e.currentTarget.value)
                                        }}
                                    />
                                </Form.Group>
                            </>
                        )}
                        {(pathName === 'youtube-video-descriptions') && (
                            <Form.Group className="mb-3">
                                <div className="d-flex align-items-center justify-content-between">
                                    <Form.Label>{t('What is this paragraph talking about?')}</Form.Label>
                                    <small className="text-muted">{textAreaValue.length}/100</small>
                                </div>
                                <Form.Control
                                    name="video_title"
                                    required
                                    as="textarea"
                                    type="text"
                                    placeholder={t('learn how to boost your traffic with an AI-Powered content writing tool.')}
                                    rows="9"
                                    maxLength={100}
                                    value={textAreaValue}
                                    onChange={(e) => {
                                        setTextAreaValue(e.currentTarget.value)
                                    }}
                                />
                            </Form.Group>
                        )}
                        {(pathName === 'youtube-tag-generator') && (
                            <Form.Group className="mb-3">
                                <Form.Label>{t('Enter your video title or a keyword')}</Form.Label>
                                <Form.Control
                                    name="video_title"
                                    required
                                    type="text"
                                    placeholder="yoga"
                                />
                            </Form.Group>
                        )}
                        {(pathName === 'twitter-threads-creation') && (
                            <Form.Group className="mb-3">
                                <div className="d-flex align-items-center justify-content-between">
                                    <Form.Label>{t('What is this thread about')}</Form.Label>
                                    <small className="text-muted">{textAreaValue.length}/500</small>
                                </div>
                                <Form.Control
                                    name="thread_content"
                                    required
                                    as="textarea"
                                    type="text"
                                    placeholder={t('The 5 best Tesla safety features')}
                                    rows="9"
                                    maxLength={500}
                                    value={textAreaValue}
                                    onChange={(e) => {
                                        setTextAreaValue(e.currentTarget.value)
                                    }}
                                />
                            </Form.Group>
                        )}
                        {(pathName === 'twitter-post-ideas') && (
                            <Form.Group className="mb-3">
                                <div className="d-flex align-items-center justify-content-between">
                                    <Form.Label>{t('What is this tweet about')}</Form.Label>
                                    <small className="text-muted">{textAreaValue.length}/500</small>
                                </div>
                                <Form.Control
                                    name="tweet_content"
                                    required
                                    as="textarea"
                                    type="text"
                                    placeholder={t('Tesla Cars')}
                                    rows="9"
                                    maxLength={500}
                                    value={textAreaValue}
                                    onChange={(e) => {
                                        setTextAreaValue(e.currentTarget.value)
                                    }}
                                />
                            </Form.Group>
                        )}
                        {(pathName === 'linkedin-profile-posts') && (
                            <>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('Audience')}</Form.Label>
                                    <Form.Control
                                        name="audience"
                                        required
                                        type="text"
                                        placeholder={t('Freelancers, Designers, Teenagers...')}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('What is this post about')}</Form.Label>
                                    <Form.Control
                                        name="post_content"
                                        required
                                        type="text"
                                        placeholder={t('Release of our app')}
                                    />
                                </Form.Group>
                            </>
                        )}
                        {(pathName === 'quora-answer-writing') && (
                            <Form.Group className="mb-3">
                                <div className="d-flex align-items-center justify-content-between">
                                    <Form.Label>{t('Quora Question')}</Form.Label>
                                    <small className="text-muted">{textAreaValue.length}/200</small>
                                </div>
                                <Form.Control
                                    name="question"
                                    required
                                    as="textarea"
                                    type="text"
                                    placeholder={t('What is something that rich people know that the rest of us would benefit from?')}
                                    rows="9"
                                    maxLength={500}
                                    value={textAreaValue}
                                    onChange={(e) => {
                                        setTextAreaValue(e.currentTarget.value)
                                    }}
                                />
                            </Form.Group>
                        )}

                        {(pathName === 'personal-bio-writing') && (
                            <>
                                <Row className="mb-3">
                                    <Form.Group as={Col}>
                                        <Form.Label>{t('Full Name')}</Form.Label>
                                        <Form.Control
                                            name="full_name"
                                            required
                                            type="text"
                                            placeholder={t('Elon Musk')}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="validationCustom02">
                                        <Form.Label>{t('Current Position')}</Form.Label>
                                        <Form.Control
                                            name="current_position"
                                            required
                                            type="text"
                                            placeholder={t('"CEO at Tesla')}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col}>
                                        <Form.Label>{t('Current Industry')}</Form.Label>
                                        <Form.Control
                                            name="current_industry"
                                            required
                                            type="text"
                                            placeholder={t('Cars')}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="validationCustom02">
                                        <Form.Label>{t('Current City')}</Form.Label>
                                        <Form.Control
                                            name="current_city"
                                            required
                                            type="text"
                                            placeholder={t('Austin, Texas')}
                                        />
                                    </Form.Group>
                                </Row>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('Childhood')}</Form.Label>
                                    <Form.Control
                                        name="childhood"
                                        required
                                        type="text"
                                        placeholder={t('Born and raised in United State')}
                                    />
                                </Form.Group>
                                <Row className="mb-3">
                                    <Form.Group as={Col}>
                                        <Form.Label>{t('Hobbies')}</Form.Label>
                                        <Form.Control
                                            name="hobbies"
                                            required
                                            type="text"
                                            placeholder={t('Bitcoin, Trade')}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="validationCustom02">
                                        <Form.Label>{t('Interests')}</Form.Label>
                                        <Form.Control
                                            name="interests"
                                            required
                                            type="text"
                                            placeholder={t('Girl')}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col}>
                                        <Form.Label>{t('Skills')}</Form.Label>
                                        <Form.Control
                                            name="skills"
                                            required
                                            type="text"
                                            placeholder={t('Talking')}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="validationCustom02">
                                        <Form.Label>{t('Character Traits')}</Form.Label>
                                        <Form.Control
                                            name="character_traits"
                                            required
                                            type="text"
                                            placeholder={t('Hard Worker')}
                                        />
                                    </Form.Group>
                                </Row>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('Point of View')}</Form.Label>
                                    <Form.Select name="point_of_view">
                                        <option value="first_person">{t('First Person')}</option>
                                        <option value="third_person">{t('Third Person')}</option>
                                    </Form.Select>
                                </Form.Group>
                            </>
                        )}
                        {['write-newsletters'].includes(pathName) && (
                            <>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('Tone of voice')}</Form.Label>
                                    <Form.Select name="tone_of_voice">
                                        <option value="professional">{t('Professional')}</option>
                                        <option value="childish">{t('Childish')}</option>
                                        <option value="luxurious">{t('Luxurious')}</option>
                                        <option value="friendly">{t('Friendly')}</option>
                                        <option value="confident">{t('Confident')}</option>
                                        <option value="exciting">{t('Exciting')}</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('Subject')}</Form.Label>
                                    <Form.Control
                                        name="subject"
                                        required
                                        type="text"
                                        placeholder={t('fursan Card Points')}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('Company Name')}</Form.Label>
                                    <Form.Control
                                        name="company_name"
                                        required
                                        type="text"
                                        placeholder={t('Saudi Airlines')}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <Form.Label>{t('Business Description?')}</Form.Label>
                                        <small className="text-muted">{textAreaValue.length}/200</small>
                                    </div>
                                    <Form.Control
                                        name="business_description"
                                        required
                                        as="textarea"
                                        type="text"
                                        placeholder={t('Briefly describe what your business is about.')}
                                        rows="9"
                                        maxLength={500}
                                        value={textAreaValue}
                                        onChange={(e) => {
                                            setTextAreaValue(e.currentTarget.value)
                                        }}
                                    />
                                </Form.Group>
                            </>
                        )}
                        {['meta-description-creation'].includes(pathName) && (
                            <>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('Type of Page')}</Form.Label>
                                    <Form.Select name="type_of_page">
                                        <option value="landing_page">{t('Landing page')}</option>
                                        <option value="product_page">{t('Product page')}</option>
                                        <option value="category_page">{t('Category page')}</option>
                                        <option value="blog_article">{t('Blog article')}</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('Website')}</Form.Label>
                                    <Form.Control
                                        name="website_name"
                                        required
                                        type="text"
                                        placeholder={t('Shoppe, Google')}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <Form.Label>{t('Website Description?')}</Form.Label>
                                        <small className="text-muted">{textAreaValue.length}/200</small>
                                    </div>
                                    <Form.Control
                                        name="website_description"
                                        required
                                        as="textarea"
                                        type="text"
                                        placeholder={t('Briefly describe what your website or business is about.')}
                                        rows="9"
                                        maxLength={500}
                                        value={textAreaValue}
                                        onChange={(e) => {
                                            setTextAreaValue(e.currentTarget.value)
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('Targeted Keyword')}</Form.Label>
                                    <Form.Control
                                        name="targeted_keyword"
                                        required
                                        type="text"
                                        placeholder={t('car insurance, nyc business lawyer,...')}
                                    />
                                </Form.Group>

                            </>
                        )}
                        {['write-emails'].includes(pathName) && (
                            <>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('Tone of voice')}</Form.Label>
                                    <Form.Select name="tone_of_voice">
                                        <option value="professional">{t('Professional')}</option>
                                        <option value="childish">{t('Childish')}</option>
                                        <option value="luxurious">{t('Luxurious')}</option>
                                        <option value="friendly">{t('Friendly')}</option>
                                        <option value="confident">{t('Confident')}</option>
                                        <option value="exciting">{t('Exciting')}</option>
                                    </Form.Select>
                                </Form.Group>
                                <Row className="mb-3">
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Sender's Name")}</Form.Label>
                                        <Form.Control
                                            name="sender_name"
                                            required
                                            type="text"
                                            placeholder={t('Adriana')}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("Recipient's Name")}</Form.Label>
                                        <Form.Control
                                            name="recipient_name"
                                            required
                                            type="text"
                                            placeholder={t('Elon Musk')}
                                        />
                                    </Form.Group>
                                </Row>
                                <Form.Group className="mb-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <Form.Label>{t("Sender's Information")}</Form.Label>
                                        <small className="text-muted">{textAreaValue.length}/200</small>
                                    </div>
                                    <Form.Control
                                        name="sender_information"
                                        required
                                        as="textarea"
                                        type="text"
                                        placeholder={t('e.g. A rising star')}
                                        rows="2"
                                        maxLength={500}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <Form.Label>{t("Recipient's Information")}</Form.Label>
                                        <small className="text-muted">{textAreaValue.length}/200</small>
                                    </div>
                                    <Form.Control
                                        name="recipient_information"
                                        required
                                        as="textarea"
                                        type="text"
                                        placeholder={t('e.g. Owner of Amazon, an e-commerce retailer.')}
                                        rows="2"
                                        maxLength={500}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <Form.Label>{t("Email About")}</Form.Label>
                                        <small className="text-muted">{textAreaValue.length}/200</small>
                                    </div>
                                    <Form.Control
                                        name="email_about"
                                        required
                                        as="textarea"
                                        type="text"
                                        placeholder={t('Contracting for future works')}
                                        rows="2"
                                        maxLength={500}
                                    />
                                </Form.Group>
                            </>
                        )}
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center justify-content-between w-100">
                                <div className="d-flex bg-light rounded tool-variants align-items-center ps-3 pe-1">
                                    <span className="fw-bold tool-variants-number">{variants}</span>
                                    <div className="d-flex flex-column ms-2">
                                        <a
                                            onClick={() => setVariants(variants + 1)}
                                            role="button"
                                            className="text-secondary"
                                        >
                                            <Icon icon="ic:baseline-plus" width="20" />
                                        </a>
                                        <a
                                            onClick={() => setVariants(variants - 1)}
                                            role="button"
                                            className="text-secondary"
                                        >
                                            <Icon icon="ic:baseline-minus" width="20" />
                                        </a>
                                    </div>
                                </div>
                                <Button className="ms-2 btn btn-primary btn-lg flex-grow-1" type="submit" disabled={isRunning}>
                                    {isRunning && (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>)}
                                    {!isRunning && (<span>{t('Generate')}</span>)}
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
            <div className="col-lg-8 col-12 mt-lg-0 mt-3">
                <div className="card bg-transparent overflow-hidden">
                    <div className="ps-3 pe-3 py-2 bg-white d-flex align-items-center justify-content-between">
                        <div>
                            <button
                                onClick={() => setTabIndex(0)}
                                className={`btn btn-sm rounded-pill me-2 ${tabIndex === 0 && 'btn-primary'}`}>{t('New')}</button>
                            <button
                                onClick={() => setTabIndex(1)}
                                className={`btn btn-sm rounded-pill me-2 ${tabIndex === 1 && 'btn-primary'}`}>{t('Saved')}</button>
                        </div>
                    </div>
                    <div className="p-3">
                        {tabIndex === 0 && (
                            <>
                                {!!variantsContent.length && (
                                    <div className="d-grid gap-2">
                                        {variantsContent.map((text, index) => (
                                            <div
                                                key={index}
                                                className="card"
                                            >
                                                {(processes.includes(index)) && (
                                                    <div dir="ltr" className="w-100 h-100 d-flex justify-content-center align-items-center mt-5">

                                                        <Timer isRunning={isRunning} />

                                                        <span className="visually-hidden">{t('Loading...')}</span>
                                                    </div>
                                                )}
                                                <div className="mx-3 mt-3" style={{ minHeight: 64 }}>
                                                    <div
                                                        className="text-result-card"
                                                        dangerouslySetInnerHTML={{ __html: text }}
                                                    />
                                                    {(!processes.includes(index)) && (
                                                        <div className="d-flex flex-row-reverse">
                                                            <CopyButton
                                                                text={text}
                                                            />
                                                            <button
                                                                onClick={() => !savedIndexes.includes(index) && saveContent(text, index)}
                                                                className="btn border-0 text-secondary">
                                                                {savedIndexes.includes(index) && (
                                                                    <>
                                                                        <Icon icon="mdi:check-circle" className="text-success" />
                                                                        <small className="ms-1">Saved</small>
                                                                    </>
                                                                )}
                                                                {!savedIndexes.includes(index) && (
                                                                    <>
                                                                        <Icon icon="mdi:content-save-all-outline" />
                                                                        <small className="ms-1">Save</small>
                                                                    </>
                                                                )}
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                                {!variantsContent.length && (
                                    <>
                                        <p className="text-center">{t('Generate results by filling up the form on the left and clicking on Generate')}</p>
                                        <div className="d-flex align-items-center flex-column text-center p-5">
                                            <div className="w-48px rounded d-flex justify-content-center align-items-center" style={{ backgroundColor: hexToRgbA(sectionObject.icon_color) }}>
                                                <Icon icon={sectionObject.icon_name} style={{ color: sectionObject.icon_color }} width="28" />
                                            </div>
                                            <h3 className="mt-3">{t(sectionObject.title)}</h3>
                                            <p className="mt-3">{t(sectionObject.description)}</p>

                                        </div>
                                    </>
                                )}
                            </>
                        )}
                        {tabIndex === 1 && (
                            <div className="d-grid gap-2">
                                {!savedContents.length && (
                                    <p className="text-center m-5">{t('This is where your saved content will appear.')}</p>
                                )}
                                {savedContents.map((item, index) => (
                                    <div
                                        key={index}
                                        className="card"
                                    >
                                        <div className="mx-3 mt-3" style={{ minHeight: 64 }}>
                                            <div
                                                className="text-result-card"
                                                dangerouslySetInnerHTML={{ __html: item.content }}>
                                            </div>
                                            <div className="d-flex flex-row-reverse">
                                                {deletingId === item.id && (
                                                    <button
                                                        onClick={() => deleteContent(item.id, index)}
                                                        className="btn border-0 text-danger">
                                                        <Icon icon="mdi:delete-outline" />
                                                        <small className="ms-1">{t('Delete ?')}</small>
                                                    </button>
                                                )}
                                                {deletingId !== item.id && (
                                                    <button
                                                        onClick={() => setDeletingId(item.id)}
                                                        className="btn border-0 text-secondary">
                                                        <Icon icon="mdi:delete-outline" />
                                                        <small className="ms-1">{t('Delete')}</small>
                                                    </button>
                                                )}
                                                <CopyButton
                                                    text={item.content}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect(({ auth }) => ({ auth }))(CommonToolPage);
