import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { NewtonsCradle } from '@uiball/loaders';
import { Icon } from "@iconify/react";

const Timer = ({ isRunning }) => {
    const { t } = useTranslation();
    const [seconds, setSeconds] = useState(0);

    const [totalMilliseconds, setTotalMilliseconds] = useState(0);

    useEffect(() => {
        let interval;

        if (isRunning) {
            interval = setInterval(() => {
                setTotalMilliseconds(prevTotalMilliseconds => prevTotalMilliseconds + 10);
            }, 10);
        } else {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [isRunning]);

    const formatTime = (milliseconds) => {
        const totalSeconds = Math.floor(milliseconds / 1000);
        const minutes = Math.floor(totalSeconds / 60);
        const remainingSeconds = totalSeconds % 60;
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');
        const formattedMilliseconds = String(milliseconds % 1000).padStart(3, '0').slice(0, 2);
        const formattedTime = `${formattedMinutes}:${formattedSeconds}.${formattedMilliseconds}`;
        return formattedTime;
    };

    return (


        <div className="d-flex align-items-center flex-column text-center p-5">
            <div dir="ltr" className="w-48px rounded d-flex justify-content-center align-items-center">
                {/* <NewtonsCradle
                    size={40}
                    speed={1}
                    color="#0052cc"
                /> */}
                {/* <Icon icon="iconoir:timer" width="48" style="color: #0052cc" /> */}
            </div>

            <div className="timer">
                {totalMilliseconds <= 90000 ? (
                    <>
                        <p className="m-2">{t('Estimated time is')} <span className="time">{t('90 seconds')}</span></p>
                        <h2 className="mt-3 h2">{formatTime(totalMilliseconds)}</h2>
                    </>
                ) : (
                    <>
                        <p className="mt-2">{t('Estimated time is')} <span className="time">{t('90 seconds')}</span></p>
                        <h2 className="mt-3 h2">{formatTime(totalMilliseconds)}</h2>
                    </>
                )}
            </div>
        </div>
    );
};

export default Timer;