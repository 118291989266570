import React, { useState } from "react";
import { connect } from "react-redux";
import Form from 'react-bootstrap/Form';
import API from "../../helpers/Axios";
import { useTranslation } from "react-i18next";
import UserAvatar from "../../components/UserAvatar";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

const SettingsPage = ({ auth, dispatch }) => {
    const { t, i18n } = useTranslation();
    const linkTo = useNavigate();
    const [isChangeProfileSubmitting, setIsChangeProfileSubmitting] = useState(false);
    const [isChangePassSubmitting, setIsChangePassSubmitting] = useState(false);
    const [validated, setValidated] = useState(false);
    const [profileSetErrors, setProfileSetErrors] = useState({});

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false || phoneNumberError) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            setIsChangeProfileSubmitting(true);
            const data = new FormData();
            data.append('name', name);
            data.append('email', email);
            data.append('age', age);
            data.append('purpose', purpose);
            data.append('mobile', mobile);
            artworkFile && data.append('artwork', artworkFile)
            API.post('auth/user/settings/profile', data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(res => {
                dispatch({ type: 'UPDATE_USER_INFO', user: res.data });
                setIsChangeProfileSubmitting(false);
                if (artworkFile) {
                    setCurrentAvatar(res.data.artwork_url);
                }
                setTimeout(() => {
                    if (res.data.name) {
                        if (auth.user.subscription) {
                            linkTo('/dashboard'); // Redirect to '/dashboard' if subscription is truthy
                        } else {
                            if (auth.user.tokens > 0) {
                                linkTo('/dashboard'); // Redirect to '/dashboard/plans' if subscription is falsy

                            } else {
                                linkTo('/dashboard'); // Redirect to '/dashboard/plans' if subscription is falsy
                            }
                        }
                    }
                }, 100);

            }).catch(error => {
                setIsChangeProfileSubmitting(false);
                setProfileSetErrors(error.response.data.errors);
            });
        }
        setValidated(true);
        event.preventDefault();
    };
    const [currentAvatar, setCurrentAvatar] = useState(auth.user.artwork_url);
    const [artworkFile, setArtworkFile] = useState(null);
    const artworksSelectedHandler = (e) => {
        setArtworkFile(e.target.files[0]);
        setCurrentAvatar(URL.createObjectURL(e.target.files[0]));
    }

    const [isChangePassError, setIsChangePassError] = useState(false);
    const [passwordSetErrors, setPasswordSetErrors] = useState({});
    const [passValidated, setPassValidated] = useState(false);
    const handleChangePassSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            const data = new FormData(event.target);
            setIsChangePassSubmitting(true);
            API.post('auth/user/settings/password', data)
                .then(res => {
                    setIsChangePassSubmitting(false);
                    setIsChangePassError(false);
                }).catch(error => {
                    setIsChangePassSubmitting(false);
                    setPasswordSetErrors(error.response.data.errors);
                });
        }
        setPassValidated(true);
        event.preventDefault();
    };
    const [name, setName] = useState(auth.user.name);
    const [email, setEmail] = useState(auth.user.email);

    const [age, setAge] = useState(auth.user.age);
    const [purpose, setPurpose] = useState(auth.user.purpose);
    const [mobile, setMobile] = useState(auth.user.mobile);
    const [phoneNumberError, setPhoneNumberError] = useState('');


    const handleAgeChange = (e) => {
        setAge(e.target.value);
    };

    const handlePurposeChange = (e) => {
        setPurpose(e.target.value);
    };

    const handleMobileChange = (e) => {
        const phoneNumberValue = e.target.value;

        setMobile(e.target.value);
        const phoneRegex = /^\d{8,13}$/;

        if (!phoneRegex.test(phoneNumberValue)) {
            setPhoneNumberError('Please enter a valid phone number.');
        } else {
            setPhoneNumberError('');
        }
    };


    return (
        <>
            <div className="container px-lg-3 px-0" style={{ maxWidth: 800 }}>
                <div className="card p-4">
                    <h5 className="mb-4">{t('Settings')}</h5>
                    <Form noValidate validated={validated} onSubmit={handleSubmit} encType="multipart/form-data">
                        <div className="d-flex mb-5">

                            {auth.user.age && (

                                <><div className="flex-shrink-0">
                                    <UserAvatar
                                        name={auth.user.name}
                                        url={currentAvatar}
                                        width="108" />
                                </div><div className="flex-grow-1 ms-3">
                                        <button className="btn btn-outline-primary position-relative" type="button">
                                            <span>{t('Upload new image')}</span>
                                            <input
                                                style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    right: 0,
                                                    bottom: 0,
                                                    opacity: 0,
                                                    cursor: 'pointer',
                                                    zIndex: 1,
                                                }}
                                                type="file"
                                                onChange={artworksSelectedHandler}
                                                accept="image/*" />
                                        </button>
                                    </div></>
                            )}


                        </div>
                        <Form.Group className="mb-3">
                            <Form.Label>{t('Name')}</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder={t('Your name')}
                                value={name}
                                onChange={e => setName(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>{t('Email')}</Form.Label>
                            <Form.Control
                                required
                                disabled
                                type="email"
                                placeholder={t('Your Email')}
                                value={email}
                                onChange={e => {
                                    setEmail(e.target.value);
                                    const { email, ...newState } = profileSetErrors;
                                    setProfileSetErrors(newState);
                                }}
                                isValid={false}
                                isInvalid={profileSetErrors.hasOwnProperty('email')}
                            />
                            <Form.Control.Feedback type="invalid">{profileSetErrors.hasOwnProperty('email') && profileSetErrors.email[0]}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>{t('Mobile Number')}</Form.Label>
                            <Form.Control
                                type="tel"
                                placeholder={t('Enter your mobile number')}
                                value={mobile}
                                onChange={handleMobileChange}
                            />
                            {phoneNumberError && (
                                <Form.Text className="text-danger">{phoneNumberError}</Form.Text>
                            )}
                        </Form.Group>


                        <Form.Group className="mb-3">
                            <Form.Label>{t('Age')}</Form.Label>
                            <Form.Select value={age} onChange={handleAgeChange}>
                                <option >
                                    {t('Select Age')}
                                </option>
                                <option value="18-25">18 - 25</option>
                                <option value="26-35">26 - 35</option>
                                <option value="36-45">36 - 45</option>
                                <option value="46-55">46 - 55</option>
                                <option value="55+">{t('Over 55')}</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>{t('Purpose of Subscription')}</Form.Label>
                            <Form.Select value={purpose} onChange={handlePurposeChange}>
                                <option >
                                    {t('Select Purpose')}
                                </option>
                                <option value="Discover and Explore Billenda AI">
                                    {t('Discover and Explore Billenda AI')}
                                </option>
                                <option value="Create Content for My Business">
                                    {t('Create Content for My Business')}
                                </option>
                                <option value="Create Personal Content">{t('Create Personal Content')}</option>
                                <option value="Other">{t('Other')}</option>
                            </Form.Select>
                        </Form.Group>




                        <Button
                            disabled={isChangeProfileSubmitting}
                            className="btn btn-primary mt-4"
                            type="submit"
                        >
                            <span>{t('Save changes')}</span>
                            {isChangeProfileSubmitting && (
                                <span className="spinner-border spinner-border-sm ms-3" role="status" aria-hidden="true"></span>
                            )}
                        </Button>
                    </Form>
                </div>
                <div className="card p-4 mt-4 d-none">
                    <h5 className="mb-4">{t('Change Password')}</h5>
                    <Form noValidate validated={passValidated} onSubmit={handleChangePassSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Current Password</Form.Label>
                            <Form.Control
                                required
                                type="password"
                                placeholder={t('Your Current Password')}
                                name="current_password"
                                onChange={e => {
                                    const { current_password, ...newState } = passwordSetErrors;
                                    setPasswordSetErrors(newState);
                                }}
                                isInvalid={passwordSetErrors.hasOwnProperty('current_password')}
                            />
                            <Form.Control.Feedback type="invalid">{passwordSetErrors.hasOwnProperty('current_password') && passwordSetErrors.current_password[0]}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>{t('Your New Password')}</Form.Label>
                            <Form.Control
                                required
                                type="password"
                                placeholder={t('Type Your New Password')}
                                name="password"
                                onChange={e => {
                                    const { password, ...newState } = passwordSetErrors;
                                    setPasswordSetErrors(newState);
                                }}
                                isInvalid={passwordSetErrors.hasOwnProperty('password')}
                            />
                            <Form.Control.Feedback type="invalid">{passwordSetErrors.hasOwnProperty('password') && passwordSetErrors.password[0]}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>{t('Confirm New Password')}</Form.Label>
                            <Form.Control
                                required
                                type="password"
                                placeholder={t('Confirm Your New Password')}
                                name="password_confirmation"
                                onChange={e => {
                                    const { password_confirmation, ...newState } = passwordSetErrors;
                                    setPasswordSetErrors(newState);
                                }}
                                isInvalid={passwordSetErrors.hasOwnProperty('password_confirmation')}
                            />
                            <Form.Control.Feedback type="invalid">{passwordSetErrors.hasOwnProperty('password_confirmation') && passwordSetErrors.password_confirmation[0]}</Form.Control.Feedback>
                        </Form.Group>
                        <Button
                            disabled={isChangePassSubmitting}
                            className="btn btn-primary mt-4"
                            type="submit"
                        >
                            <span>{t('Save changes')}</span>
                            {isChangePassSubmitting && (
                                <span className="spinner-border spinner-border-sm ms-3" role="status" aria-hidden="true"></span>
                            )}
                        </Button>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default connect(({ auth }) => ({ auth }))(SettingsPage);
