import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ToolCard from "../../components/ToolCard";
import ToolCardDisable from "../../components/ToolCardDisable";
import ToolCardNoSubscription from "../../components/ToolCardNoSubscription";
import tools from "../../helpers/tools.json";
import { useTranslation } from "react-i18next";

const AllToolsPage = ({ auth }) => {
    const { t } = useTranslation();
    const [tabIndex, setTabIndex] = useState(
        JSON.parse(localStorage.getItem("toolsTabIndex")) || 0
    );

    useEffect(() => {
        localStorage.setItem("toolsTabIndex", JSON.stringify(tabIndex));
    }, [tabIndex]);

    // Function to check if item.alt_name exists in auth.user.subscription.templates
    const isAltNameInTemplates = (altName) => {
        return auth?.user?.subscription?.templates?.includes(altName);
    };

    const isAltNameInAddonTemplates = (altName) => {
        const today = new Date();

        const addonTemplatesJSON = auth?.user?.addon_templates || '[]'; // Default to an empty array if it's not provided

        // Parse the JSON string into an array
        const addonTemplates = JSON.parse(addonTemplatesJSON);

        if (!Array.isArray(addonTemplates)) {
            // Handle the case where addon_templates is not an array
            return false; // or do something else
        }

        return addonTemplates.some((template) => {
            const templateDate = new Date(template.created_at);

            // Calculate the date one month from templateDate
            const oneMonthLater = new Date(templateDate);
            oneMonthLater.setMonth(oneMonthLater.getMonth() + 100);

            return (
                template.alt_name === altName &&
                templateDate < today && // Check if created_at is before today
                today <= oneMonthLater // Check if today is less than or equal to created_at + 1 month
            );
        });
    };


    return (
        <>
            <div className="d-flex align-items-center">
                <h1>{t('All Tools')}</h1>
            </div>
            <ul className="nav nav-tabs nav-tabs-none-bg mt-3">
                {tools.map((item, index) => (
                    <li key={index} className="nav-item">
                        <button
                            onClick={() => setTabIndex(index)}
                            className={`nav-link text-dark d-flex align-items-center ${tabIndex === index && "active"
                                }`}
                            aria-current="page"
                        >
                            <span> {t(item.title)}</span>
                            <span className="ms-2 badge rounded-pill fw-light">
                                {item.tools.length}
                            </span>
                        </button>
                    </li>
                ))}
            </ul>
            {auth?.user?.subscription ? (
                <div className="row mt-4 tools-list g-lg-4 g-3">
                    {tools[tabIndex].tools.map((item, index) => (
                        <React.Fragment key={index}>
                            {(isAltNameInTemplates(item.alt_name) || isAltNameInAddonTemplates(item.alt_name)) ? (
                                <ToolCard item={item} />
                            ) : (
                                <ToolCardDisable item={item} />
                            )}
                        </React.Fragment>
                    ))}
                </div>
            ) : (
                <div className="row mt-4 tools-list g-lg-4 g-3">
                    {tools[tabIndex].tools.map((item, index) => (
                        <React.Fragment key={index}>

                            {isAltNameInAddonTemplates(item.alt_name) ? (
                                <ToolCard item={item} />
                            ) : (
                                <ToolCardNoSubscription item={item} />

                            )}
                        </React.Fragment>
                    ))}
                </div>
            )}
        </>
    );
};

export default connect(({ auth }) => ({ auth }))(React.memo(AllToolsPage));